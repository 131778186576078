import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import { parsePhoneNumberWithError as parsePhoneNumber } from "libphonenumber-js";

import { AuthTypes } from "graphql_globals";
import { DeprecatedCheckboxWithLabel } from "common/form/inputs/checkbox";
import Button from "common/core/button";

import Styles from "./index.module.scss";
import type { UserAuthOptions_user_User } from "../../settingsv2/sidebar_settings/profile/overview/user_security_form/user_auth_options/index_query.graphql";
import AlertMessage from "../../core/alert_message";

const MESSAGES = defineMessages({
  sms: {
    id: "726ea25c-c193-4bf9-9617-72dbad59c1b2",
    defaultMessage: "Text message",
  },
  email: {
    id: "c2f02eab-5547-49a0-8a0e-0099ab372179",
    defaultMessage: "Email",
  },
  totp: {
    id: "86234515-4645-459a-8104-bd3f73f5fa11",
    defaultMessage: "Authenticator App",
  },
});

export type MfaOptionsProps = {
  authTypes: ReturnType<typeof useMfaOptions>["authTypes"];
  setAuthTypes: ReturnType<typeof useMfaOptions>["setAuthTypes"];
};

type Props = {
  authenticationRequirements: AuthenticationRequirement[];
};

type AuthenticationRequirement = {
  authType: AuthTypes;
};

type SupportedAuthRequirementsProps = {
  authTypes: ReturnType<typeof useMfaOptions>["authTypes"];
};

type UserMfaOptionsProps = {
  authTypes: ReturnType<typeof useMfaOptions>["authTypes"];
  setAuthTypes: ReturnType<typeof useMfaOptions>["setAuthTypes"];
  user: UserAuthOptions_user_User | null;
};

export function getSupportedAuthenticationRequirements({
  authTypes,
}: SupportedAuthRequirementsProps) {
  const results = [];
  if (authTypes[AuthTypes.SMS]) {
    results.push({
      authType: AuthTypes.SMS,
    });
  }
  if (authTypes[AuthTypes.EMAIL]) {
    results.push({
      authType: AuthTypes.EMAIL,
    });
  }
  if (authTypes[AuthTypes.TIME_BASED_ONE_TIME_PASSWORD]) {
    results.push({
      authType: AuthTypes.TIME_BASED_ONE_TIME_PASSWORD,
    });
  }
  return results;
}

export function useMfaOptions({ authenticationRequirements }: Props) {
  const [authTypes, setAuthTypes] = useState({
    [AuthTypes.SMS]: false,
    [AuthTypes.EMAIL]: false,
    [AuthTypes.TIME_BASED_ONE_TIME_PASSWORD]: false,
  });

  useEffect(() => {
    if (authenticationRequirements.length > 0) {
      const baseAuthTypes = { ...authTypes };
      authenticationRequirements.forEach((requirement) => {
        if (
          requirement.authType === AuthTypes.SMS ||
          requirement.authType === AuthTypes.EMAIL ||
          requirement.authType === AuthTypes.TIME_BASED_ONE_TIME_PASSWORD
        ) {
          baseAuthTypes[requirement.authType] = true;
        }
      });
      setAuthTypes(baseAuthTypes);
    }
  }, [authenticationRequirements]);

  return {
    authTypes,
    setAuthTypes,
  };
}

export function PhoneNumberEdit({ phoneNumber }: { phoneNumber: null | string }) {
  const navigate = useNavigate();
  return (
    <div className={Styles.phoneNumberEdit}>
      {phoneNumber && (
        <p className={Styles.phoneNumber}>{parsePhoneNumber(phoneNumber).formatInternational()}</p>
      )}
      <Button
        data-automation-id="mfa-edit-phone-number"
        buttonColor="action"
        variant="secondary"
        role="link"
        onClick={() => navigate("/mfa_sms_setup?redirectUponCompletion=/settings/profile/overview")}
      >
        <FormattedMessage
          id="0d45282f-057b-4d35-85d7-bb21c2a415cd"
          defaultMessage="{hasNumber, select, true{Change} other{Setup}} number"
          values={{ hasNumber: Boolean(phoneNumber) }}
        />
      </Button>
    </div>
  );
}

export function MfaOptions({ authTypes, setAuthTypes, user }: UserMfaOptionsProps) {
  // `user` is null when rendering the MFA options for an organization admin to
  // configure MFA for his team members.
  // `user` is non-null when a user sets up MFA on his account.
  const intl = useIntl();
  const emailChecked = Boolean(authTypes[AuthTypes.EMAIL]);
  const [smsChecked, setSmsChecked] = useState(Boolean(authTypes[AuthTypes.SMS]));
  const displayUserEmail = user && emailChecked;
  const displayUserPhone = user && smsChecked;

  return (
    <>
      <div className={Styles.checkboxes}>
        <DeprecatedCheckboxWithLabel
          label={intl.formatMessage(MESSAGES.sms)}
          checked={smsChecked}
          automationId={`${AuthTypes.SMS}-checkbox`}
          onChange={() => {
            setSmsChecked(!smsChecked);
            if (!user) {
              setAuthTypes({
                ...authTypes,
                [AuthTypes.SMS]: !authTypes[AuthTypes.SMS],
              });
            }
          }}
        />
        {smsChecked && (
          <AlertMessage kind="warning" className={Styles.smsAlertMessage}>
            <FormattedMessage
              id="e59ef596-b136-47b0-9573-eb8c90fdb1ce"
              defaultMessage="Text Message (SMS) is considered less secure because SMS can be intercepted by attacks through various techniques, such as SIM swap fraud. For a higher level of protection, we recommend considering an alternative second factor such as an Authenticator App."
            />
          </AlertMessage>
        )}
        {displayUserPhone && <PhoneNumberEdit phoneNumber={user.phoneNumber} />}
      </div>
      {/* We are moving away from EMAIL as an MFA option. */}
      {/* Render the component only for users/organizations who currently have it setup. */}
      {emailChecked && (
        <div className={displayUserEmail ? Styles.emailChecked : Styles.checkboxes}>
          <DeprecatedCheckboxWithLabel
            label={intl.formatMessage(MESSAGES.email)}
            checked={emailChecked}
            automationId={`${AuthTypes.EMAIL}-checkbox`}
            onChange={() => {
              setAuthTypes({
                ...authTypes,
                [AuthTypes.EMAIL]: !authTypes[AuthTypes.EMAIL],
              });
            }}
          />
          {displayUserEmail && <p className={Styles.email}>{user.email}</p>}
        </div>
      )}
      <div className={Styles.checkboxes}>
        <DeprecatedCheckboxWithLabel
          label={intl.formatMessage(MESSAGES.totp)}
          checked={Boolean(authTypes[AuthTypes.TIME_BASED_ONE_TIME_PASSWORD])}
          automationId={`${AuthTypes.TIME_BASED_ONE_TIME_PASSWORD}-checkbox`}
          onChange={() => {
            setAuthTypes({
              ...authTypes,
              [AuthTypes.TIME_BASED_ONE_TIME_PASSWORD]:
                !authTypes[AuthTypes.TIME_BASED_ONE_TIME_PASSWORD],
            });
          }}
        />
      </div>
    </>
  );
}
