import { Outlet, useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useEffect } from "react";

import { useQuery } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import { SidebarTabLink } from "common/sidebar";
import { useOnboardingV2 } from "util/feature_detection";
import { SettingsHeader, SettingsPageWrapper } from "common/settingsv2/common";
import {
  NotaryActivationStatus,
  NotaryAccountStatuses,
  NotaryOnboardingStatuses,
  NotaryCapacityType,
  NotaryComplianceStatuses,
  NotaryProfileInvalidFields,
} from "graphql_globals";
import { isNotaryCallReady, isNotaryIHN, isNotaryIHNCallReady } from "common/notary/capacity";

import Styles from "./index.module.scss";
import { SidebarIncompleteIcon } from "./incomplete_icon";
import { getTabs } from "./section_tabs";
import { useSettingsSections } from "./section";
import ProfileSettingsQuery, {
  type ProfileSettings_viewer_user as User,
  type ProfileSettings_viewer_user_notaryProfile_capacities as NotaryCapacities,
} from "./index_query.graphql";
import type { UpdateNotaryProfileVariables } from "./update_notary_profile_mutation.graphql";

type Props = {
  user: User;
  sectionId?: string;
  isMenuItem?: boolean;
};

type PaymentProfile = {
  complianceStatus: NotaryComplianceStatuses;
  capacities: NotaryCapacities[];
};

type UpdateProfileInput = Omit<UpdateNotaryProfileVariables["input"], "id"> & {
  userPhoneNumber?: string;
  countryCallingCode?: string;
};
export type MaybeUpdateUserFn = (input?: UpdateProfileInput | null) => Promise<User>;

export const NOTARY_PROFILE_ROUTE = "notary/profile";

export function independentNotaryMissingPayment(notaryProfile: PaymentProfile) {
  const { complianceStatus, capacities } = notaryProfile;
  const isCompliant = complianceStatus === NotaryComplianceStatuses.COMPLIANT;
  return (
    isCompliant &&
    capacities.some((cap) => {
      return (
        cap.type === NotaryCapacityType.BRING_YOUR_OWN_TRANSACTIONS &&
        cap.validation.invalidFields.some(
          (invalidField) =>
            invalidField === NotaryProfileInvalidFields.INVALID_PAYMENT ||
            invalidField === NotaryProfileInvalidFields.MISSING_STRIPE_CONNECT,
        )
      );
    })
  );
}

export function useNotaryAccountStatus(user: User) {
  const notaryProfile = user.notaryProfile!;
  const { accountStatus, complianceStatus, capacities } = notaryProfile;
  const disabled = accountStatus === NotaryAccountStatuses.DISABLED;
  const isCompliant = complianceStatus === NotaryComplianceStatuses.COMPLIANT;
  // note: the backend can update onboardingStatus back to `INITIATED` if something like license expires
  const incompleteOnboarding =
    notaryProfile.onboardingStatus !== NotaryOnboardingStatuses.COMPLETED;
  const pausedOnboarding = notaryProfile.onboardingStatus === NotaryOnboardingStatuses.PAUSED;
  // PayoutOptions are housed in Organization settings, while the rest are in signer app. Prevent
  // direction to the signer app when they need Org settings for missing payment
  const incompleteProfile = Boolean(notaryProfile.validation.invalidFields.length);
  const nstWithMissingPayment = independentNotaryMissingPayment(notaryProfile);
  const odnWithMissingNNA =
    isCompliant &&
    capacities.some((cap) => {
      return (
        cap.type === NotaryCapacityType.ON_DEMAND &&
        cap.validation.invalidFields.some(
          (invalidField) =>
            invalidField === NotaryProfileInvalidFields.INVALID_NNA_TRAINING_EXPIRY ||
            invalidField === NotaryProfileInvalidFields.INVALID_BACKGROUND_CHECK_EXPIRY,
        )
      );
    });
  const odnWaiting =
    isCompliant &&
    !odnWithMissingNNA &&
    capacities.some((cap) => {
      return (
        cap.type === NotaryCapacityType.ON_DEMAND &&
        (cap.status === NotaryActivationStatus.PENDING ||
          cap.status === NotaryActivationStatus.REJECTED)
      );
    });

  // For IHNs whose org admin disabled their ability to take calls
  const ihnDisabled =
    isNotaryIHN(notaryProfile) && isCompliant && !isNotaryIHNCallReady(notaryProfile);
  // For any notary that needs review from Notarize admins whether they're newly onboarded
  // or updated something in their profile
  const notaryRequiresReapproval = !isNotaryCallReady(notaryProfile);

  return {
    disabled,
    incompleteOnboarding,
    incompleteProfile,
    nstWithMissingPayment,
    odnWithMissingNNA,
    odnWaiting,
    ihnDisabled,
    notaryRequiresReapproval,
    pausedOnboarding,
  };
}

const TITLE = (
  <FormattedMessage id="9e00451e-2f00-45ca-8fa8-ccee1cb16f72" defaultMessage="Notary profile" />
);

export function SettingsSidebarNotaryProfileLink({ user, isMenuItem }: Props) {
  const { incompleteProfile } = useNotaryAccountStatus(user);
  return (
    <SidebarTabLink to={"notary/profile"} isMenuItem={isMenuItem}>
      {TITLE}
      {incompleteProfile && <SidebarIncompleteIcon />}
    </SidebarTabLink>
  );
}

function ProfileSettings({ user }: Props) {
  const { sections } = useSettingsSections(user);
  const tabs = getTabs(sections);
  return <SettingsHeader title="Notary Profile" tabs={tabs} />;
}

function WrappedProfileWizard() {
  const navigate = useNavigate();

  const { data, loading } = useQuery(ProfileSettingsQuery);
  const onboardingV2 = useOnboardingV2();
  // if notary has not completed onboarding, we don't let them see notary profile settings
  useEffect(() => {
    if (
      data?.viewer.user?.notaryProfile &&
      data.viewer.user.notaryProfile.onboardingStatus !== NotaryOnboardingStatuses.COMPLETED
    ) {
      navigate(
        onboardingV2 ? "/settings/notary/onboarding/overview" : "/settings/notary/onboarding",
      );
    }
  }, [data]);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <div className={Styles.profileWizard}>
      <SettingsPageWrapper>
        <ProfileSettings user={data!.viewer.user!} />
        <Outlet context={{ user: data!.viewer.user! }} />
      </SettingsPageWrapper>
    </div>
  );
}

export default WrappedProfileWizard;
