import { FormattedMessage, useIntl } from "react-intl";

import { SettingsTitle } from "common/settingsv2/common";
import Tab from "common/core/tabs/tab";
import LoadingIndicator from "common/core/loading_indicator";
import { useActiveOrganization } from "common/account/active_organization";
import { NetworkStatus, useQuery } from "util/graphql";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";
import { usePermissions } from "common/core/current_user_role";

import LogoAndHeaderQuery from "./index.query.graphql";
import OrgLogo from "./logo";
import CustomHeader from "./header";
import SenderInfo from "../sender_info";

export const LOGO_HEADER_BRAND_ROUTE = "logo-header";

export function LogoAndHeaderTab() {
  return (
    <Tab to={LOGO_HEADER_BRAND_ROUTE}>
      {
        <FormattedMessage
          id="0f2c0cd5-ef33-4b1b-bc6e-3829e0d8e95d"
          defaultMessage="Brand settings"
        />
      }
    </Tab>
  );
}

export function LogoAndHeaderBrandSettings() {
  useA11y().useDocumentEntitler({
    title: useIntl().formatMessage(useDocumentTitles().settingsBrandLogoAndHeader),
  });
  const [activeOrganizationId] = useActiveOrganization();
  const { data, networkStatus } = useQuery(LogoAndHeaderQuery, {
    variables: { organizationId: activeOrganizationId! },
  });
  const { hasPermissionFor } = usePermissions();
  const canEditOrgLogo = hasPermissionFor("editOrganizationDetails");
  // using networkStatus to only show loading screen on initial load but not on any refetches
  const loading = networkStatus === NetworkStatus.loading;

  if (loading) {
    return <LoadingIndicator />;
  }

  const organization = data ? data.organization! : null;
  if (!organization) {
    throw new Error(`Expected organization, got nothing.`);
  }
  if (organization.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${organization.__typename}.`);
  }

  const { organizationBrand, logoUrl, name } = organization;

  return (
    <>
      <SettingsTitle>
        <SenderInfo organizationBrand={organizationBrand!} organizationName={organization.name!} />
        {canEditOrgLogo && <OrgLogo organization={organization} />}
        <CustomHeader organizationBrand={organizationBrand!} orgName={name} logoUrl={logoUrl} />
      </SettingsTitle>
    </>
  );
}
