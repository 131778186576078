import { type ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import type { UseFormReturn } from "common/core/form";
import {
  ProofRequirementFormValues,
  type ProofRequirementField,
} from "common/proof_requirements/common";
import type { OrganizationApiKeyPayer } from "graphql_globals";
import Link from "common/core/link";

import type { PayerOrganization } from "../payer_organization.graphql";
import {
  OrganizationPayer,
  ProofRequirement,
  type OrganizationPayerField,
  type ProofField,
} from "../common";

export type IdentifyFormValues = {
  id: string;
  active: boolean;
  name: string;
  payer: OrganizationApiKeyPayer | undefined;
  proofRequirement: ProofRequirementField;
  ccRecipients: { email: string }[];
};

type ProofRequirementProps<FormFields extends ProofField> = ComponentProps<
  typeof ProofRequirement<FormFields>
>;
export function IdentifyProofRequirement<FormFields extends ProofField>(props: {
  form: ProofRequirementProps<FormFields>["form"];
  defaultAuthenticationRequirement: ProofRequirementProps<FormFields>["defaultAuthenticationRequirement"];
  availableProofRequirements: ProofRequirementProps<FormFields>["availableProofRequirements"];
}) {
  return (
    <ProofRequirement
      form={props.form}
      defaultAuthenticationRequirement={props.defaultAuthenticationRequirement}
      availableProofRequirements={props.availableProofRequirements}
      proofSectionSubcopy={
        <FormattedMessage
          id="556a2bab-48a2-4717-81f0-7a6bee830d07"
          defaultMessage="Select at least one identity verification option."
        />
      }
      showTipwell={false}
      availableEasylinkProofRequirements={[
        ProofRequirementFormValues.IAL2,
        ProofRequirementFormValues.CA_SELFIE,
        ProofRequirementFormValues.KBA,
      ]}
      required
    />
  );
}

export function IdentifyPayer<FormFields extends OrganizationPayerField>(props: {
  organization: PayerOrganization;
  form: UseFormReturn<FormFields>;
}) {
  return (
    <OrganizationPayer
      {...props}
      cardDescription={
        <FormattedMessage
          id="00c6bd85-f30c-4a8e-bdf4-ccae3943adce"
          defaultMessage="Identify EasyLinks only support payment via your organization. <link>Learn more</link>"
          values={{
            link: (text) => (
              <Link href="https://support.proof.com/hc/en-us/articles/24114416196631-IDENTIFY-a-Proof-product">
                {text}
              </Link>
            ),
          }}
        />
      }
    />
  );
}
