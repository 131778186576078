import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import type { Observable } from "rxjs";
import classnames from "classnames";
import { type ComponentProps, type ReactNode } from "react";

import { CompletionRequirement, type ProcessingStates } from "graphql_globals";
import DocumentUploadHandler, {
  type DocumentUploaderHandlerRenderProps,
} from "common/document/multidoc_uploader/document_upload_handler";
import MultiUploader, {
  type UploadedDocument,
} from "common/document/multidoc_uploader/multi_uploader";
import { useMobileScreenClass } from "common/core/responsive";
import { useIsAuthenticated } from "common/authentication";
import type {
  Branding_viewer_referralInfo_organizationBrand as OrganizationBrand,
  Branding_viewer_referralInfo_publicOrganization as Organization,
} from "common/account/guest_signup/branding_query.graphql";
import { NotarizeNetwork } from "common/core/logo/notarize_network";
import { PDFViewer } from "common/pdf/pspdfkit/viewer";
import { ButtonStyledLink } from "common/core/button/button_styled_link";
import { segmentTrack } from "util/segment";
import { SEGMENT_EVENTS } from "constants/analytics";
import TosV2 from "common/tos";
import { AVAILABLE_FEATURES } from "constants/organization";
import { CURRENT_EXPERIMENTS, EXPERIMENT_GROUP_DEFAULT } from "common/experiments/group";
import { Heading } from "common/core/typography";

import Styles from "./index.module.scss";
import {
  SplitLayoutHeading,
  SplitLayoutSubheading,
  SplitLayoutSteps,
  SplitLayoutActionButton,
} from "../shared/split_layout";
import { QRBlock } from "../mobile_web_qr_code";

const MESSAGES = defineMessages({
  tosActionText: {
    id: "a7c6e3b9-5f5b-4e2a-8f6a-2d3a1c7a5b3a",
    defaultMessage: "By adding a document and clicking 'Continue',",
  },
  headerNotary: {
    id: "7930ae2e-26e2-4e95-90e4-18677fb243c1",
    defaultMessage: "Connect with an online notary, now.",
  },
  headerNotaryExpt: {
    id: "71143243-7811-4da0-bb69-38035270a6d3",
    defaultMessage: "Upload your documents",
  },
  headerEsignDocsProvided: {
    id: "c329c166-a63a-45dd-86d3-abde509f0f92",
    defaultMessage: "eSign your document",
  },
  headerEsign: {
    id: "a7fe53a8-91b3-435f-911b-35851ada951f",
    defaultMessage: "Upload documents for eSign",
  },
  headerNSTNotary: {
    id: "a8a1daa0-cd34-4fe8-9d16-24055449b7c0",
    defaultMessage: "Connect with a notary at {orgName}",
  },
});

type Props = {
  uploadStrategy: (file: File) => Observable<
    {
      id: string;
      name: string;
      status: ProcessingStates;
      mimeType: string;
      processingError?: string;
      classification?: { category: string | null; languages: string[] } | null;
      metadata: { pagesTotal: number | null } | null;
    }[]
  >;
  completeStrategy: (
    documents: { id: string; name: string; bundlePosition: number }[],
  ) => Promise<unknown>;
  completionRequirement?: CompletionRequirement;
  paymentCoveringOrgName?: string;
  organizationBrand: OrganizationBrand | null;
  orgFeatureFlags: Organization["featureFlags"];
  children?: ReactNode;
  signerPrice?: number | null;
  analyticsPrefix?: string;
  providedDocuments?: { url: string }[];
  onProvidedDocumentsContinue?: () => void;
  onProvidedDocumentsPreview?: () => void;
  isRetail?: boolean;
  onDocumentDeleteCb?: (doc: UploadedDocument) => void;
  showIneligibleWarning?: boolean;
  forNst: boolean;
  uploadCopyExpt?: (typeof CURRENT_EXPERIMENTS.RetailUpload)[keyof typeof CURRENT_EXPERIMENTS.RetailUpload];
};

export default function GuestUpload(props: Props) {
  const {
    uploadStrategy,
    completeStrategy,
    completionRequirement = CompletionRequirement.NOTARIZATION,
    paymentCoveringOrgName,
    organizationBrand,
    orgFeatureFlags = [],
    children,
    signerPrice,
    analyticsPrefix,
    providedDocuments,
    onProvidedDocumentsContinue,
    onProvidedDocumentsPreview,
    forNst,
    isRetail,
    onDocumentDeleteCb,
    showIneligibleWarning,
    uploadCopyExpt,
  } = props;
  const isNotarization = completionRequirement === CompletionRequirement.NOTARIZATION;
  const isMobile = useMobileScreenClass();
  const isAuthenticated =
    useIsAuthenticated() && !(organizationBrand?.header && organizationBrand.organizationLogoUrl);
  const signerPriceString = signerPrice ? `$${(signerPrice / 100).toFixed(2)}` : null;
  const hasDocsProvided = providedDocuments && providedDocuments.length > 0;

  const organizationFeatures = orgFeatureFlags.filter((f) => f.value === "true").map((f) => f.key);
  const docsProvidedPreviewCopyExptEnabled =
    hasDocsProvided &&
    organizationFeatures.includes(AVAILABLE_FEATURES.DOCS_PROVIDED_EASYLINK_EXPT);

  const steps: ComponentProps<typeof SplitLayoutSteps>["steps"] = [];
  if (!uploadCopyExpt || uploadCopyExpt === EXPERIMENT_GROUP_DEFAULT) {
    if (hasDocsProvided) {
      steps.push({
        heading: docsProvidedPreviewCopyExptEnabled ? (
          <FormattedMessage
            id="09584bb1-a094-4e53-a347-b06cbdc2e214"
            defaultMessage="Review and edit the document template"
          />
        ) : (
          <FormattedMessage
            id="7d17d3ce-80b3-4180-ac72-f76aa0f246f2"
            defaultMessage="Review your document"
          />
        ),
        info: docsProvidedPreviewCopyExptEnabled ? (
          <FormattedMessage
            id="d68eb78c-7de0-4998-a03a-0925ded689f6"
            defaultMessage="We provide an editable document template. Fill in what you can."
          />
        ) : (
          <FormattedMessage
            id="bfd4f1a3-969e-42ae-a9a2-897e7ca289ec"
            defaultMessage="Read the contents of the document before signing{isNotarization, select, true { in front of the notary.} other {.}}"
            values={{ isNotarization }}
          />
        ),
      });
    } else {
      steps.push({
        heading: (
          <FormattedMessage
            id="bfd4f1a3-969e-42ae-a9a2-897e7ca289ec"
            defaultMessage="Upload or scan your document"
          />
        ),
        info: (
          <FormattedMessage
            id="bfd4f1a3-969e-42ae-a9a2-897e7ca289ec"
            defaultMessage="Upload the full document as a .pdf or .docx file."
          />
        ),
      });
    }
    if (isNotarization) {
      steps.push(
        {
          heading: (
            <FormattedMessage
              id="0577d8ec-f842-47e4-9525-4d1084ee5dcd"
              defaultMessage="Verify your identity"
            />
          ),
          info: (
            <FormattedMessage
              id="bfd4f1a3-969e-42ae-a9a2-897e7ca289ec"
              defaultMessage="Answer a few questions and take a photo of your unexpired government issued ID."
            />
          ),
        },
        {
          heading: (
            <FormattedMessage
              id="3a9b2ec0-dd76-4760-996a-a0a16f85a509"
              defaultMessage="Connect with a notary on a video call"
            />
          ),
          info: (
            <FormattedMessage
              id="bfd4f1a3-969e-42ae-a9a2-897e7ca289ec"
              defaultMessage="Notaries are based in Virginia, Nevada, Florida, and Texas, and can notarize documents for use in all 50 states."
            />
          ),
        },
      );
    } else {
      steps.push({
        heading: (
          <FormattedMessage
            id="9c5d5f3d-5d2f-4648-ace2-f734b8e3eef4"
            defaultMessage="Apply your signature"
          />
        ),
        info: (
          <FormattedMessage
            id="8dd7ddbc-f42a-4f2d-8c6a-bb064c53f00d"
            defaultMessage="Select a digital signature and apply it to the document."
          />
        ),
      });
    }
    steps.push({
      heading: (
        <FormattedMessage
          id="27445c5f-8447-4679-86fb-819b247597a2"
          defaultMessage="Download or send document to another person"
        />
      ),
      info: (
        <FormattedMessage
          id="bfd4f1a3-969e-42ae-a9a2-897e7ca289ec"
          defaultMessage="Share your documents within seconds."
        />
      ),
    });
  } else if (uploadCopyExpt === CURRENT_EXPERIMENTS.RetailUpload.A) {
    steps.push(
      {
        heading: (
          <FormattedMessage
            id="215335be-578d-4b65-88ee-4c70b2abed58"
            defaultMessage="Your document - PDF, DOCX, or a scanned file."
          />
        ),
      },
      {
        heading: (
          <FormattedMessage
            id="5d7c414d-8f7c-4855-897d-ceebc7574888"
            defaultMessage="Your ID - A valid, unexpired photo ID."
          />
        ),
      },
      {
        heading: (
          <FormattedMessage
            id="87499811-e9bc-492d-a911-82cf69226f40"
            defaultMessage="Your device - A WiFi-enabled device with a camera."
          />
        ),
      },
      {
        heading: (
          <FormattedMessage
            id="651031c5-8fac-4b53-8262-32341c5b8d8f"
            defaultMessage="Your email - For confirmation and updates."
          />
        ),
      },
    );
  } else if (uploadCopyExpt === CURRENT_EXPERIMENTS.RetailUpload.B) {
    steps.push(
      {
        heading: (
          <FormattedMessage
            id="bcce2fe6-39a1-4d77-a1e5-49682e474d05"
            defaultMessage="Have your document ready. It can be PDF, DOCX or paper."
          />
        ),
      },
      {
        heading: (
          <FormattedMessage
            id="525e6ec7-cac6-480f-8dcd-11d5e8d11d6a"
            defaultMessage="Have your ID ready to take a photo of your unexpired ID."
          />
        ),
      },
      {
        heading: (
          <FormattedMessage
            id="6381ff7a-4e31-492f-be41-1282965a355c"
            defaultMessage="Have a WiFi enabled device with a camera."
          />
        ),
      },
      {
        heading: (
          <FormattedMessage
            id="4349ef9a-533c-4fed-a16a-a5d3ef09d30e"
            defaultMessage="Have your email address ready."
          />
        ),
      },
    );
  }

  let subHeading = (
    <FormattedMessage
      id="b986344a-eed9-4307-82ea-f42cf771596d"
      defaultMessage="Adopt an electronic signature and apply it to your documents{hasSignerPriceString, select, true {. {priceString}} other {.}}"
      values={{
        hasSignerPriceString: !!signerPriceString || isRetail,
        priceString: (
          <FormattedMessage
            id="2b256427-8d58-4f61-9c70-ab30c784c628"
            defaultMessage="Price starts at {signerPriceString}."
            values={{
              signerPriceString: signerPriceString || (isRetail ? "$4" : null),
            }}
          />
        ),
      }}
    />
  );

  if (forNst) {
    subHeading = (
      <FormattedMessage
        id="6fd8897c-36f4-4158-b602-85fccc23a8df"
        defaultMessage="Connect with a notary at {orgName}, to get your document notarized by the right professional."
        values={{ orgName: organizationBrand?.organizationName }}
      />
    );
  } else if (isNotarization) {
    if (uploadCopyExpt === CURRENT_EXPERIMENTS.RetailUpload.A) {
      subHeading = (
        <FormattedMessage
          id="6a80eca4-465e-4b3f-bab4-e7be977c3815"
          defaultMessage="With Proof's {notarizeNetworkImage}, connect with an online notary 24/7 and complete your notarization in minutes."
          values={{
            notarizeNetworkImage: <NotarizeNetwork />,
          }}
        />
      );
    } else if (uploadCopyExpt === CURRENT_EXPERIMENTS.RetailUpload.B) {
      subHeading = (
        <FormattedMessage
          id="b1e638f0-b9aa-4d65-ad5b-d72a06a77516"
          defaultMessage="Have these materials handy and connect with the {notarizeNetworkImage} on Proof 24/7 to notarize your document in minutes."
          values={{
            notarizeNetworkImage: <NotarizeNetwork />,
          }}
        />
      );
    } else if (paymentCoveringOrgName) {
      subHeading = (
        <FormattedMessage
          id="f83621e3-cd4e-416b-a0bf-f5994f35a219"
          defaultMessage="Connect with the {notarizeNetworkImage} on Proof 24/7 and notarize your document in minutes. {coveringOrgName} will cover the cost of this transaction - there will be no cost to you."
          values={{
            coveringOrgName: paymentCoveringOrgName,
            notarizeNetworkImage: <NotarizeNetwork />,
          }}
        />
      );
    } else {
      subHeading = (
        <FormattedMessage
          id="ec06f844-3585-47bc-bd80-edbc157efe2b"
          defaultMessage="Connect with the {notarizeNetworkImage} on Proof 24/7 and notarize {docsProvidedPreviewCopyExptEnabled, select, true {this} other {your}} document in minutes{hasSignerPriceString, select, true {. {priceString}} other {.}}"
          values={{
            notarizeNetworkImage: <NotarizeNetwork />,
            hasSignerPriceString: !!signerPriceString || isRetail,
            priceString: (
              <FormattedMessage
                id="9dcfd67b-ab16-4dc8-a97c-36b3e9dab1df"
                defaultMessage="Price starts at {signerPriceString}."
                values={{
                  signerPriceString: signerPriceString || (isRetail ? "$25" : null),
                }}
              />
            ),
            docsProvidedPreviewCopyExptEnabled,
          }}
        />
      );
    }
  } else if (paymentCoveringOrgName) {
    subHeading = (
      <FormattedMessage
        id="6fd8897c-36f4-4158-b602-85fccc23a8df"
        defaultMessage="Adopt an electronic signature and apply it to your documents. {coveringOrgName} will cover the cost of this transaction - there will be no cost to you."
        values={{ coveringOrgName: paymentCoveringOrgName }}
      />
    );
  }

  return (
    <>
      <div
        className={classnames(Styles.multiUpload, {
          [Styles.authenticatedWrapper]: isAuthenticated,
        })}
      >
        <DocumentUploadHandler
          uploadStrategy={uploadStrategy}
          cacheUploadedDocuments={!hasDocsProvided}
        >
          {({
            uploadedDocuments$,
            onSelectFiles,
            onDocumentDelete,
          }: DocumentUploaderHandlerRenderProps) => {
            return (
              <>
                <div className={Styles.top} data-growth-id="upload-container">
                  <div className={Styles.innerTop}>
                    <div className={Styles.column}>
                      <SplitLayoutHeading data-growth-id="upload-heading">
                        {UploadHeading({
                          isNotarization,
                          uploadCopyExpt,
                          hasDocsProvided,
                          forNst,
                          orgName: organizationBrand?.organizationName,
                        })}
                      </SplitLayoutHeading>

                      <SplitLayoutSubheading data-growth-id="upload-subheading">
                        {subHeading}
                      </SplitLayoutSubheading>

                      <div data-growth-id="notarization-steps">
                        {uploadCopyExpt === CURRENT_EXPERIMENTS.RetailUpload.A && (
                          <Heading level="h3" textStyle="headingSix">
                            <FormattedMessage
                              id="d2d15299-7a14-4746-b0e2-46def342079f"
                              defaultMessage="What you'll need:"
                            />
                          </Heading>
                        )}
                        <SplitLayoutSteps
                          steps={steps}
                          clickable={!uploadCopyExpt || uploadCopyExpt === EXPERIMENT_GROUP_DEFAULT}
                        />

                        {isMobile && !hasDocsProvided && <Tos />}

                        {!isMobile && hasDocsProvided && onProvidedDocumentsContinue && (
                          <SplitLayoutActionButton
                            onClick={onProvidedDocumentsContinue}
                            automationId="guest-upload-action-button"
                          >
                            {isNotarization ? (
                              docsProvidedPreviewCopyExptEnabled ? (
                                <FormattedMessage
                                  id="2967f53a-9952-446c-aff3-a329179a4e87"
                                  defaultMessage="Review and edit"
                                />
                              ) : (
                                <FormattedMessage
                                  id="a81d1146-18b9-48ba-a22b-11026d4ed793"
                                  defaultMessage="Notarize now"
                                />
                              )
                            ) : (
                              <FormattedMessage
                                id="50e52c50-9eb9-4fcc-8fbf-4b3f7deb1d8c"
                                defaultMessage="Sign now"
                              />
                            )}
                          </SplitLayoutActionButton>
                        )}
                        {uploadCopyExpt && uploadCopyExpt !== EXPERIMENT_GROUP_DEFAULT && (
                          <QRBlock />
                        )}
                      </div>
                    </div>

                    {!isMobile &&
                      (hasDocsProvided ? (
                        <div className={Styles.column}>
                          <div className={Styles.previewContainer}>
                            <PDFViewer
                              className={Styles.previewViewer}
                              url={providedDocuments[0].url || ""}
                            />
                            <div className={Styles.previewButton}>
                              <ButtonStyledLink
                                onClick={() => {
                                  segmentTrack(
                                    SEGMENT_EVENTS.GUEST_UPLOAD_LOGIN_PREVIEW_PREVIEW_CLICK,
                                  );
                                  onProvidedDocumentsPreview?.();
                                }}
                                variant="tertiary"
                                buttonSize="condensed"
                                buttonColor="action"
                              >
                                <FormattedMessage
                                  id="e8b4d526-173b-474e-901b-dcb646232e0e"
                                  defaultMessage="Preview document"
                                />
                              </ButtonStyledLink>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className={Styles.column} data-growth-id="uploader-desktop">
                          <MultiUploader
                            aria-label="Document uploader"
                            onSelectFiles={onSelectFiles}
                            onDocumentDelete={(doc) => {
                              if (onDocumentDeleteCb) {
                                onDocumentDeleteCb(doc);
                              }
                              onDocumentDelete(doc);
                            }}
                            uploadedDocuments$={uploadedDocuments$}
                            completeStrategy={completeStrategy}
                            enableScanning
                            rowsClassName={Styles.uploadRows}
                            rowsWrapperClassName={Styles.uploadRowsWrapper}
                            continueButtonClassName={Styles.uploadContinueButton}
                            analyticsPrefix={analyticsPrefix}
                            showIneligibleWarning={showIneligibleWarning}
                            uploadExptFormat={
                              Boolean(uploadCopyExpt) && uploadCopyExpt !== EXPERIMENT_GROUP_DEFAULT
                            }
                          />
                          <Tos />
                        </div>
                      ))}

                    {children}
                  </div>
                </div>
                {isMobile && (
                  <div className={Styles.bottom} data-growth-id="uploader-mobile">
                    <MultiUploader
                      aria-label="Document uploader"
                      onSelectFiles={onSelectFiles}
                      onDocumentDelete={(doc) => {
                        if (onDocumentDeleteCb) {
                          onDocumentDeleteCb(doc);
                        }
                        onDocumentDelete(doc);
                      }}
                      uploadedDocuments$={uploadedDocuments$}
                      completeStrategy={completeStrategy}
                      rowsClassName={Styles.uploadRowsMobile}
                      enableScanning
                      isAddButtonFixedMobile
                      completionRequirement={completionRequirement}
                      hasDocsProvided={hasDocsProvided}
                      onProvidedDocumentsContinue={onProvidedDocumentsContinue}
                      onProvidedDocumentsPreview={onProvidedDocumentsPreview}
                      showIneligibleWarning={showIneligibleWarning}
                    />
                  </div>
                )}
              </>
            );
          }}
        </DocumentUploadHandler>
      </div>
    </>
  );
}

function Tos() {
  const intl = useIntl();
  return (
    <TosV2
      textColor="subtle"
      size="small"
      automationId="guest-signup-terms-of-service"
      className={Styles.tos}
      underlined
      actionText={intl.formatMessage(MESSAGES.tosActionText)}
    />
  );
}

function UploadHeading({
  isNotarization,
  uploadCopyExpt,
  hasDocsProvided,
  forNst,
  orgName,
}: {
  isNotarization: boolean;
  uploadCopyExpt: ComponentProps<typeof GuestUpload>["uploadCopyExpt"];
  hasDocsProvided?: boolean;
  forNst: boolean;
  orgName: string | undefined;
}) {
  const intl = useIntl();
  let heading;

  if (forNst) {
    heading = MESSAGES.headerNSTNotary;
  } else if (isNotarization) {
    if (Boolean(uploadCopyExpt) && uploadCopyExpt !== EXPERIMENT_GROUP_DEFAULT) {
      heading = MESSAGES.headerNotaryExpt;
    } else {
      heading = MESSAGES.headerNotary;
    }
  } else {
    heading = hasDocsProvided ? MESSAGES.headerEsignDocsProvided : MESSAGES.headerEsign;
  }
  return intl.formatMessage(heading, { orgName });
}
