import { useMemo, memo, useState } from "react";
import { FormattedMessage } from "react-intl";

import { userFullName } from "util/user";
import { fromZonedTime } from "common/core/format/date";
import { browserTimeZone } from "util/date";
import Button from "common/core/button";
import { DeprecatedRadioButton } from "common/form/inputs/radio";
import { b } from "util/html";

import { useCanvasSealCreator } from ".";
import type { NotaryProfileWizardSealCreatorModal as User } from "./creator_modal_fragment.graphql";
import Styles from "./generator.module.scss";

type Props = {
  user: User;
  onConfirm: (pngBlob: Blob) => Promise<unknown>;
  isUploading: boolean;
};

function SealGenerator({ user, isUploading, onConfirm }: Props) {
  const { licenseExpiry, notaryId, usState, county, address, isAttorney } = user.notaryProfile!;
  const [isCityText, setIsCityText] = useState(false);
  const isMissouri = usState.abbreviation === "MO";
  const cityInput = isMissouri && isCityText ? county : isMissouri ? null : address.city;

  const { loading, canvasRef, getSealFile } = useCanvasSealCreator({
    name: userFullName(user),
    expirationDate: useMemo(() => {
      const timeZone = browserTimeZone();
      if (!timeZone) {
        throw new Error(`Could not get time zone for notary ${notaryId}`);
      }
      return licenseExpiry ? fromZonedTime(licenseExpiry, timeZone) : null;
    }, [licenseExpiry]),
    registrationNumber: notaryId,
    usState: usState.abbreviation as Parameters<typeof useCanvasSealCreator>[0]["usState"],
    county: isCityText ? null : county,
    city: cityInput,
    isAttorney,
    address,
  });

  const handleConfirm = () => getSealFile().then(onConfirm);
  return (
    <div className={Styles.notaryProfileWizardSealCreatorGenerator}>
      <FormattedMessage
        id="d08424b8-b285-4771-8168-55b27440a80c"
        defaultMessage="We require notaries to use a standard seal design on our platform. <b>Please confirm the information in the seal below is correct. If the information is incorrect, go back and update your commission details.</b>"
        tagName="p"
        values={{ b }}
      />
      {usState.abbreviation === "MO" && (
        <div className={Styles.radios}>
          <FormattedMessage
            id="88a4bf40-17b6-402b-ba58-227a25d59357"
            defaultMessage="Select county or city commission location"
            tagName="p"
          />
          <DeprecatedRadioButton
            labelText={
              <FormattedMessage
                id="d7e4e546-7a3b-48d6-82c8-752060dacda5"
                defaultMessage={"{county} County"}
                values={{ county }}
              />
            }
            className={Styles.options}
            radioValue={false}
            onChange={() => setIsCityText(false)}
            groupValue={isCityText}
          />
          <DeprecatedRadioButton
            labelText={
              <FormattedMessage
                id="e6e58f90-0243-4aa2-b63b-dcf5353d8ab9"
                defaultMessage={"City of {county}"}
                values={{ county }}
              />
            }
            className={Styles.options}
            radioValue
            onChange={() => setIsCityText(true)}
            groupValue={isCityText}
          />
        </div>
      )}
      <canvas ref={canvasRef} />
      <Button
        buttonColor="action"
        variant="primary"
        disabled={loading}
        isLoading={isUploading}
        onClick={handleConfirm}
      >
        <FormattedMessage
          id="273e736c-71d1-460e-8cf8-564b6ea4071e"
          defaultMessage="Confirm and apply"
        />
      </Button>
    </div>
  );
}

export default memo(SealGenerator);
