import "./index.scss";

import { Children, cloneElement, Component } from "react";
import PropTypes from "prop-types";

import { SENSITIVE_CLASS } from "common/core/sensitive_label";

/** @deprecated - please use components in common/core/form */
export class DeprecatedRadioGroup extends Component {
  static displayName = "RadioGroup";

  static propTypes = {
    selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    onChange: PropTypes.func,
    children: PropTypes.node.isRequired,
  };

  render() {
    const { children, ...restProps } = this.props;

    const childrenWithProps = Children.map(children, (child) => {
      return cloneElement(child, restProps);
    });

    return <div className={`RadioGroup ${SENSITIVE_CLASS}`}>{childrenWithProps}</div>;
  }
}
