import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import type { ComponentProps, ReactNode } from "react";

import { isMobileDevice } from "util/support";
import { CompletionRequirement } from "graphql_globals";
import Svg from "common/core/svg";
// Notarization QR images
import NextQRImage from "assets/images/next-signup-upload-qr-code.svg";
import StagingQRImage from "assets/images/staging-signup-upload-qr-code.svg";
import FairfaxQRImage from "assets/images/fairfax-signup-upload-qr-code.svg";
import ProdQRImage from "assets/images/prod-signup-upload-qr-code.svg";
// eSign QR images
import NextQRImageEsign from "assets/images/next-signup-upload-qr-code-esign.svg";
import StagingQRImageEsign from "assets/images/staging-signup-upload-qr-code-esign.svg";
import FairfaxQRImageEsign from "assets/images/fairfax-signup-upload-qr-code-esign.svg";
import ProdQRImageEsign from "assets/images/prod-signup-upload-qr-code-esign.svg";
// Marketing images
import mwebScanImage from "assets/images/mobile_web_scan_demo.png";
import PhoneMeetingImage from "assets/images/phone-meeting.svg";
import QRMobileSign from "assets/images/qr-mobile-sign.png";
import EsignImage from "assets/images/esign.svg";
import Env from "config/environment";
import { Heading, Paragraph } from "common/core/typography";
import {
  Column,
  Container,
  Row,
  useMatchScreenClass,
  useMobileScreenClass,
} from "common/core/responsive";
import { WistiaPlayer } from "common/core/wistia_video";

import Styles from "./index.module.scss";

const GUEST_UPLOAD_VIDEO_ID = "z1f7ha0zdb";

type Props = {
  completionRequirement?: CompletionRequirement;
  qrStream?: string | null;
};

const MESSAGES = defineMessages({
  qrAlt: {
    id: "f50235f2-0a1e-42d7-94b2-67cd0d165c02",
    defaultMessage: "QR Code for redirect to mobile device",
  },
});

const getQRCodeImage = (hostname: string) => {
  const env = hostname.split(".")[0];
  switch (env) {
    case "next":
      return NextQRImage;
    case "staging":
      return StagingQRImage;
    case "fairfax":
      return FairfaxQRImage;
    default:
      return ProdQRImage;
  }
};

const getQRCodeImageEsign = (hostname: string) => {
  const env = hostname.split(".")[0];
  switch (env) {
    case "next":
      return NextQRImageEsign;
    case "staging":
      return StagingQRImageEsign;
    case "fairfax":
      return FairfaxQRImageEsign;
    default:
      return ProdQRImageEsign;
  }
};

function QrCode({ qrStream, isEsign }: { qrStream?: string | null; isEsign?: boolean }) {
  const intl = useIntl();
  const QRCode = isEsign ? getQRCodeImageEsign(Env.hostName) : getQRCodeImage(Env.hostName);

  let qrImage = <Svg src={QRCode} alt={intl.formatMessage(MESSAGES.qrAlt)} />;
  if (qrStream) {
    const svgDataUrlSrc = `data:image/svg+xml;base64,${btoa(qrStream)}`;

    qrImage = (
      <img
        data-pendo-id="landing-qr-code"
        src={svgDataUrlSrc}
        alt={intl.formatMessage(MESSAGES.qrAlt)}
      />
    );
  }

  return <div className={Styles.qr}>{qrImage}</div>;
}

export function QRBlock({
  completionRequirement = CompletionRequirement.NOTARIZATION,
  qrStream,
}: Props) {
  return (
    <>
      <Heading level="h2" textStyle="headingSix" className={Styles.header}>
        <FormattedMessage
          id="3d79735b-9ca7-430f-adbe-0ca75f91f019"
          defaultMessage="Only have a paper copy?"
        />
      </Heading>
      <div className={Styles.info}>
        <QrCode
          isEsign={completionRequirement === CompletionRequirement.ESIGN}
          qrStream={qrStream}
        />
        <Paragraph>
          <FormattedMessage
            id="760b1005-d342-4c22-9148-4460bf42bd79"
            defaultMessage={
              "Scan the QR code to take pictures of your paper document and {isNotarization, select, true {get your document notarized} other {sign your document}} using your phone."
            }
            values={{
              isNotarization: completionRequirement === CompletionRequirement.NOTARIZATION,
            }}
          />
        </Paragraph>
      </div>
    </>
  );
}

export function QRCodeSection({
  leftColumn,
  rightColumn,
  noSpacing = false,
  rowProps,
}: {
  leftColumn: ReactNode;
  rightColumn: ReactNode;
  noSpacing?: boolean;
  rowProps?: ComponentProps<typeof Row>;
}) {
  const isMedium = useMatchScreenClass("xs", "sm", "md");
  const rowStyles = {
    marginTop: isMedium ? 24 : 48,
    alignItems: "center",
    ...(rowProps?.style && { ...rowProps.style }),
  };

  return (
    <Container noSpacing={noSpacing} style={{ maxWidth: "1200px", width: "100%" }}>
      <Row {...rowProps} style={rowStyles}>
        <Column xs={12} md={6} lg={6}>
          {leftColumn}
        </Column>
        <Column
          xs={12}
          md={6}
          lg={6}
          style={{ display: "flex", justifyContent: isMedium ? "center" : "flex-end" }}
        >
          {rightColumn}
        </Column>
      </Row>
    </Container>
  );
}

export function RetailQRCode({
  completionRequirement,
  qrStream,
  hideQr,
}: {
  completionRequirement: CompletionRequirement;
  qrStream?: string | null;
  hideQr?: boolean;
}) {
  const isEsign = completionRequirement === CompletionRequirement.ESIGN;
  const mobileScreen = useMobileScreenClass();
  const isMedium = useMatchScreenClass("xs", "sm", "md");

  return (
    <div className={Styles.wrapper} data-growth-id="qr-mobile-container">
      <div className={Styles.container}>
        <QRCodeSection
          leftColumn={
            isEsign ? (
              <div className={Styles.leftColumnImage}>
                <Svg alt="" src={EsignImage} />
              </div>
            ) : (
              <WistiaPlayer videoId={GUEST_UPLOAD_VIDEO_ID} />
            )
          }
          rightColumn={
            <div className={Styles.guestHowContainer}>
              <div className={Styles.guestHow}>
                <Heading level="h2" textStyle="headingThree">
                  <FormattedMessage
                    id="23467ad1-1387-4916-ac32-81b453066e01"
                    defaultMessage="How this works"
                  />
                </Heading>
                <Paragraph>
                  {isEsign ? (
                    <FormattedMessage
                      id="6d0414b5-4330-48fe-a7e4-4833a634762a"
                      defaultMessage="Signing a document with Proof is easy. Simply upload or scan your document, apply your signature, and download or share your documents. Price is $4."
                    />
                  ) : (
                    <FormattedMessage
                      id="190d92f3-0f71-462a-b3b5-802774786486"
                      defaultMessage="Getting a document notarized is easy. Simply upload your docs, verify your identity and you will be connected to a notary via an online meeting. Notaries on the Notarize Network are always online and available 24/7."
                    />
                  )}
                </Paragraph>
              </div>
              {!hideQr && !isMobileDevice() && !mobileScreen && (
                <QRBlock completionRequirement={completionRequirement} qrStream={qrStream} />
              )}
            </div>
          }
          rowProps={{
            wrap: "reverse",
            style: { marginBottom: isMedium ? 24 : 48, ...(hideQr && { alignItems: "start" }) },
          }}
        />
      </div>
    </div>
  );
}

export function DocsProvidedQRCode({ qrStream, completionRequirement }: Props) {
  const isMobile = useMobileScreenClass();

  if (!qrStream || isMobileDevice() || isMobile) {
    return null;
  }

  const isNotarization = completionRequirement === CompletionRequirement.NOTARIZATION;

  return (
    <div className={Styles.wrapper} data-growth-id="qr-mobile-container">
      <div className={Styles.container}>
        <QRCodeSection
          leftColumn={
            <>
              <Heading level="h2" textStyle="headingSix" className={Styles.header}>
                {isNotarization ? (
                  <FormattedMessage
                    id="09ddb20e-6040-4d1d-8d8b-e7721dd9df99"
                    defaultMessage="Want to use your phone for the meeting?"
                  />
                ) : (
                  <FormattedMessage
                    id="bd2009b2-7c32-478f-955d-664fd9370ece"
                    defaultMessage={"Want to use your phone to sign?"}
                  />
                )}
              </Heading>

              <div className={Styles.info}>
                <QrCode qrStream={qrStream} />
                <Paragraph size="large">
                  {isNotarization ? (
                    <FormattedMessage
                      id="e32bfa4c-d1ea-4fde-9d5c-189aac3b3e27"
                      defaultMessage="Scan the QR code to set up your audio and video and get your document signed using your phone."
                    />
                  ) : (
                    <FormattedMessage
                      id="c0228364-e512-4549-813c-0fee90448833"
                      defaultMessage="Scan the QR code and sign your document using your phone."
                    />
                  )}
                </Paragraph>
              </div>
            </>
          }
          rightColumn={
            isNotarization ? (
              <Svg className={Styles.rightColumnImage} alt="" src={PhoneMeetingImage} />
            ) : (
              <img className={Styles.rightColumnImage} alt="" src={QRMobileSign} />
            )
          }
        />
      </div>
    </div>
  );
}

export function MobileWebQRCode(props: Props) {
  const mobileScreen = useMobileScreenClass();
  if (isMobileDevice() || mobileScreen) {
    return null;
  }

  return (
    <div className={Styles.wrapper} data-growth-id="qr-mobile-container">
      <div className={Styles.container}>
        <QRCodeSection
          leftColumn={<QRBlock {...props} />}
          rightColumn={
            <img
              className={Styles.rightColumnImage}
              src={mwebScanImage}
              alt=""
              role="presentation"
            />
          }
        />
      </div>
    </div>
  );
}
