import { forwardRef, type ComponentPropsWithoutRef, type Ref } from "react";

import Zones from "./timezones";
import { Select } from "../select";

const ITEMS = Object.freeze(
  Zones.map((name) => ({
    label: name.replace("_", " "),
    value: name,
  })),
);

type Props = {
  items?: ComponentPropsWithoutRef<typeof Select>["items"];
  displayRequiredAsterisk?: boolean;
} & Omit<ComponentPropsWithoutRef<typeof Select>, "items">;

function Timezone(props: Props, ref: Ref<HTMLSelectElement>) {
  return <Select {...props} items={props.items || ITEMS} ref={ref} />;
}

export default forwardRef(Timezone);
