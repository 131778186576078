import "./index.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import Button from "common/core/button";
import { truncate } from "util/string";
import { formatMB } from "util/number";
import { buildFileTypeErrorMessage } from "util/uploader";

/**
 * @description
 * This component provides a button and a file selector input that can be used to
 * upload any asset to S3. While it can be called on its own, it is preferable to use
 * ./document_upload.js and ./image_upload.js, which provide a simpler interface for this
 * component for uploading documents and images, respectively. It accepts the following props:
 *   @property {object} acceptedFileTypes An object that validates the types of files that can be
 *     uploaded. These are listed in the format { "application/pdf": "PDF" }.
 *   @property {function} performUpload The specific function used to upload the selected file.
 *     See `uploadDocumentToS3` or `businessLogoUpload` in util/uploader.js as examples.
 *   @property {number} filesizeLimit The maximum file size allowed in bytes. Violations will set
 *     `state.invalidFileSize` to true and will not attempt to upload.
 *   @property {node} [actionMessage] The FormattedMessage to display inside the button. Default message is "Select".
 *   @property {string} [savedFilename] The name of the file uploaded, which will be passed to the
 *     backend as the `name:` property.
 *   @property {bool} [hideLabel] A boolean to toggle whether or not the label, which is a text field
 *     showing the name of the uploaded file, is rendered.
 *   @property {function} onBlur A callback that fires when the upload starts. Calls with an empty object.
 *   @property {function} onChange A callback that fires when the upload succeeds. Calls with the
 *     S3 key of the new file. // TODO: can this be removed and use onSuccess only?
 *   @property {function} onSuccess A callback that fires when the upload succeeds. Calls with
 *     the File object for the uploaded file.
 *   @property {function} [onFailure] A callback that fires when the upload fails. Calls with no args.
 */

/** @deprecated - please use components in common/core/form */
export class DeprecatedFileUpload extends Component {
  state = {
    file: null,
    uploading: false,
    uploadSuccess: false,
    invalidFileType: false,
    invalidFileSize: false,
  };

  // Would prefer this be on componentWillMount so we can remove the state check but it appears
  // there's an issue in redux-form where initial values aren't available until the second render.
  // This should be fixed once we upgrade to v5.2.5 as per
  // https://github.com/erikras/redux-form/issues/621
  componentDidUpdate() {
    const { savedFilename } = this.props;
    if (!this.state.file && savedFilename) {
      this.setState({ file: { name: savedFilename } });
    }
  }

  handleFileChange(ev) {
    // clear previous success / error messages
    this.setState({ invalidFileType: false, invalidFileSize: false, uploadSuccess: false });

    const file = ev.target.files[0];

    // happens when the file select dialog is opened, but the window
    // is dismissed without selecting any file / cancel is pressed
    if (file === undefined) {
      return;
    }

    if (!this.isAcceptableFileType(file)) {
      this.setState({ file: null, invalidFileType: true });
      return;
    }

    if (file.size > this.props.filesizeLimit) {
      this.setState({ file: null, invalidFileSize: true });
      return;
    }

    this.setState({ file });
    this.upload(file);
  }

  get acceptedFileFormats() {
    return Object.keys(this.props.acceptedFileTypes || {});
  }

  get fileTypeErrorMessage() {
    const acceptedFileExtensions = [...new Set(Object.values(this.props.acceptedFileTypes || []))];
    return `Invalid file. ${buildFileTypeErrorMessage(acceptedFileExtensions)}`;
  }

  isAcceptableFileType = (file) => {
    const fileExt = file.name ? `.${file.name.split(".").pop().toLowerCase()}` : "";
    // file is acceptable if we have a MIME match or extension match
    return this.acceptedFileFormats.some(
      (el) => (!!file.type && el === file.type) || fileExt === el.toLowerCase(),
    );
  };

  triggerFileSelect = () => {
    this.inputElement.click();
  };

  removeFile = () => {
    this.setState({ file: null, uploadSuccess: false });
    this.inputElement.value = "";
    this.props.removeUpload?.();
  };

  upload(file) {
    const { uploading } = this.state;

    if (!uploading) {
      this.props.onBlur({});
      this.setState({ uploading: true, uploadSuccess: false });

      this.props
        .performUpload(file)
        .then((s3Payload) => this.props.onChange(s3Payload.key)) // TODO: can this be removed and use onSuccess only?
        .then(() => {
          this.setState({ uploading: false, uploadSuccess: true });
          this.props.onSuccess(this.state.file);
        })
        .catch(() => {
          this.setState({
            file: null,
            uploading: false,
            uploadSuccess: false,
          });
          this.props.onFailure();
        });
    }
  }

  render() {
    const { file, uploading, uploadSuccess, invalidFileType, invalidFileSize } = this.state;
    const { actionMessage, hideLabel, inline } = this.props;

    const cx = classnames("FileUpload", {
      "FileUpload--with-message": invalidFileSize || invalidFileType || uploadSuccess,
      "FileUpload--inline": inline,
    });

    const label = file === null ? "Select a file" : file.name;
    const labelCx = classnames("ellipsis", {
      placeholder: file === null,
    });

    const buttonCx = inline ? "FileUpload--inline--button" : "FileUpload--button";

    const uploadMessage = uploadSuccess ? (
      <span className="upload-message">File {truncate(file.name, 25)} uploaded successfully</span>
    ) : null;

    const invalidFileTypeMessage = invalidFileType ? (
      <span className="upload-message error">{this.fileTypeErrorMessage}</span>
    ) : null;

    const invalidFileSizeMessage = invalidFileSize ? (
      <span className="upload-message error">
        Invalid file. No files over {formatMB(this.props.filesizeLimit, 0)} MB allowed.
      </span>
    ) : null;

    return (
      <div className={cx}>
        {!hideLabel && (
          <label htmlFor="file" className={labelCx}>
            {label}
          </label>
        )}

        {this.state.file && this.props.removeUpload ? (
          <Button
            className={buttonCx}
            buttonColor="action"
            variant="primary"
            onClick={this.removeFile}
          >
            <FormattedMessage id="07297193-107a-4dee-bf42-41f7f7ae5e05" defaultMessage="Remove" />
          </Button>
        ) : (
          <Button
            className={buttonCx}
            buttonColor="action"
            variant="primary"
            isLoading={uploading}
            disabled={uploading}
            onClick={this.triggerFileSelect}
          >
            {actionMessage || (
              <FormattedMessage id="65125a67-85ca-45e8-8429-4ec9138f49f6" defaultMessage="Select" />
            )}
          </Button>
        )}

        <input
          type="file"
          id="file"
          ref={(ref) => {
            this.inputElement = ref;
          }}
          disabled={uploading}
          accept={this.acceptedFileFormats.join(",")}
          onChange={this.handleFileChange.bind(this)}
          data-automation-id="file-input"
        />

        {uploadMessage}

        {invalidFileTypeMessage}

        {invalidFileSizeMessage}
      </div>
    );
  }
}

DeprecatedFileUpload.propTypes = {
  acceptedFileTypes: PropTypes.object.isRequired,
  performUpload: PropTypes.func.isRequired,
  removeUpload: PropTypes.func,
  filesizeLimit: PropTypes.number.isRequired,
  actionMessage: PropTypes.node,
  savedFilename: PropTypes.string,
  hideLabel: PropTypes.bool,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func,
};
