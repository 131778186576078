import { Navigate } from "react-router-dom";

import { useActiveOrganization } from "common/account/active_organization";
import LoadingIndicator from "common/core/loading_indicator";
import { useShowDocTemplates, useShowEasylinks } from "common/dashboard/util";
import { useFeatureFlag } from "common/feature_gating";
import { BusinessToolsMobileDevice } from "common/proof_frame/mobile_nav/empty_states";
import { useQuery } from "util/graphql/query";
import { usePermissions } from "common/core/current_user_role";

import { ToolsSidebar } from "./tools_sidebar";
import ToolsQuery, { type Tools_node_Organization as Organization } from "./index_query.graphql";
import Styles from "./index.module.scss";

export function useShowTools(featureList: Organization["featureList"] = []) {
  const { hasPermissionFor } = usePermissions();

  // useShowDocTemplates checks the permissions for us
  const showTemplates = useShowDocTemplates(featureList);
  const showEasyLinks = useShowEasylinks(featureList) && hasPermissionFor("viewEasyLinks");
  const showReferrals =
    useFeatureFlag("transaction-referrals") &&
    (hasPermissionFor("createReferralCampaigns") || hasPermissionFor("updateReferralCampaigns"));
  return showTemplates || showEasyLinks || showReferrals;
}

export function Tools({ showMobileDeviceContent }: { showMobileDeviceContent: boolean }) {
  const [activeOrganizationId] = useActiveOrganization();
  const { data, loading } = useQuery(ToolsQuery, {
    variables: { organizationId: activeOrganizationId! },
  });

  const organization = data?.node as Organization | null;
  const hasToolsFeatures = useShowTools(organization?.featureList);

  if (loading || !data?.node) {
    return <LoadingIndicator />;
  }
  if (!hasToolsFeatures) {
    return <Navigate to="/" />;
  }
  if (showMobileDeviceContent) {
    return <BusinessToolsMobileDevice />;
  }

  return (
    <div className={Styles.wrapper}>
      <ToolsSidebar organization={organization!} />
    </div>
  );
}
