import { FormattedMessage } from "react-intl";

import { useForm } from "common/core/form";
import { useActiveOrganization } from "common/account/active_organization";
import { QueryWithLoading } from "util/graphql/query";
import { OrganizationApiKeyCompletionRequirement, OrganizationApiKeyPayer } from "graphql_globals";
import { transformProofRequirementForSubmit } from "common/proof_requirements/common";

import { CCRecipients, NameInput } from "../common";
import { IdentifyPayer, IdentifyProofRequirement, type IdentifyFormValues } from "./common";
import { CreateEasylinkLayout } from "../create";
import OrganizationSettingsQuery, {
  type OrganizationSettings_organization_Organization as Organization,
} from "../organization_settings.query.graphql";

export function CreateIdentifyEasylink() {
  const [activeOrganizationId] = useActiveOrganization();
  const form = useForm<IdentifyFormValues>({
    defaultValues: {
      proofRequirement: [],
    },
  });

  return (
    <QueryWithLoading
      query={OrganizationSettingsQuery}
      variables={{ organizationId: activeOrganizationId! }}
    >
      {({ data }) => {
        const organization = data!.organization as Organization;
        const { defaultAuthenticationRequirement, availableProofRequirements } = organization;

        return (
          <CreateEasylinkLayout
            form={form}
            title={
              <FormattedMessage
                id="da5bda5b-35da-484d-acea-c238fc8adffc"
                defaultMessage="Identify"
              />
            }
            getSubmitVariables={(values) => ({
              input: {
                name: values.name,
                payer: OrganizationApiKeyPayer.ORGANIZATION,
                completionRequirement: OrganizationApiKeyCompletionRequirement.IDENTIFY,
                minSigners: 1,
                maxSigners: 1,
                organizationId: activeOrganizationId!,
                proofRequirement: transformProofRequirementForSubmit(values.proofRequirement),
                ccRecipientEmails: values.ccRecipients.map(({ email }) => email),
              },
            })}
          >
            <NameInput form={form} />
            <IdentifyProofRequirement
              form={form}
              defaultAuthenticationRequirement={defaultAuthenticationRequirement}
              availableProofRequirements={availableProofRequirements}
            />
            <CCRecipients form={form} />
            <IdentifyPayer organization={organization} form={form} />
          </CreateEasylinkLayout>
        );
      }}
    </QueryWithLoading>
  );
}
