import { useEffect, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { reduxForm, type InjectedFormProps } from "redux-form";

import { Payer } from "graphql_globals";
import { DeprecatedRadioGroupContainer } from "common/form/inputs/radio/radio_group_container";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import { DeprecatedTextField } from "common/form/fields/text";
import { normalizeToNumber } from "util/normalize";
import Button from "common/core/button";
import { DeprecatedRadioButtonField } from "common/form/fields/radio";
import { useQuery, useMutation } from "util/graphql";
import compose from "util/compose";
import { composeValidators, getFormValues } from "util/form";
import { validatePresence } from "validators/form";
import { useActiveOrganization } from "common/account/active_organization";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_TYPES } from "constants/notifications";

import OrganizationPaymentQuery, {
  type OrganizationPayment_node_Organization as Organization,
} from "./organization_payment_query.graphql";
import UpdateOrganizationPayerMutation from "./update_organization_payer.graphql";
import UpdateOrganizationPaymentMutation from "./update_organization_payment.graphql";
import Styles from "./index.module.scss";

type FormValues = {
  notarizationFee: number;
  additionalSealFee: number;
  payerSetting: Payer;
};
type InjectedProps = InjectedFormProps<FormValues> & { formValues: FormValues };
type InnerProps = InjectedProps & {
  organization: Organization;
};

const MESSAGES = defineMessages({
  billOutside: {
    id: "920da330-1acd-480f-a683-c4fa296914af",
    defaultMessage: "Bill signers outside of Proof",
  },
  billWith: {
    id: "3f7f7d5e-0dbf-4e96-b4eb-824a179afed2",
    defaultMessage: "Signers pay using Proof",
  },
  notarizationFees: {
    id: "0ad8765d-0229-4a72-82b1-803e73942aa4",
    defaultMessage: "Notarization Fees",
  },
  additionalSealFees: {
    id: "a05e264e-d281-4c87-92d2-2fd413b8ed00",
    defaultMessage: "Additional Seal",
  },
  paymentSettingsSuccess: {
    id: "74bc1057-9444-4722-ac8a-f664beadb323",
    defaultMessage: "Payment settings successfully updated",
  },
});

function UpdatePaymentSettings(props: InnerProps) {
  const { handleSubmit, initialize, organization, valid, formValues } = props;
  const [isUpdating, setIsUpdating] = useState(false);
  const updateOrganizationPaymentMutateFn = useMutation(UpdateOrganizationPaymentMutation);
  const updateOrganizationPayerMutateFn = useMutation(UpdateOrganizationPayerMutation);
  const intl = useIntl();
  const isPaymentSpecified = Boolean(organization.paymentSpecified);

  const onSubmit = (values: FormValues) => {
    setIsUpdating(true);
    const isOrgPayer = values.payerSetting === Payer.ORGANIZATION;
    return Promise.all([
      updateOrganizationPayerMutateFn({
        variables: { input: { id: organization.id, defaultPayer: values.payerSetting } },
      }),
      updateOrganizationPaymentMutateFn({
        variables: {
          input: {
            organizationId: organization.id,
            prices: {
              notaverseAdditionalSeal: isOrgPayer ? 0 : values.additionalSealFee * 100,
              notaverseFirstSeal: isOrgPayer ? 0 : values.notarizationFee * 100,
            },
          },
        },
      }),
    ])
      .then(() => {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          message: intl.formatMessage(MESSAGES.paymentSettingsSuccess),
        });
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  useEffect(() => {
    const { notaverseFirstSeal, notaverseAdditionalSeal } = organization.activeTier.prices;
    initialize({
      payerSetting: organization.defaultPayer,
      notarizationFee: notaverseFirstSeal && isPaymentSpecified ? notaverseFirstSeal / 100 : 0,
      additionalSealFee:
        notaverseAdditionalSeal && isPaymentSpecified ? notaverseAdditionalSeal / 100 : 0,
    });
  }, []);

  const isOrgPayer = formValues.payerSetting === Payer.ORGANIZATION;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Styles.payer}>
      <p className={Styles.info}>
        <FormattedMessage
          id="04665fb4-a5e3-4813-9f4c-0edfff2cc16b"
          defaultMessage="Select the default signer payment method (This can be changed for individual transactions)"
        />
      </p>
      <DeprecatedRadioGroupContainer className={Styles.radios}>
        <DeprecatedRadioButtonField
          name="payerSetting"
          automationId="org-pays"
          radioValue={Payer.ORGANIZATION}
          labelText={intl.formatMessage(MESSAGES.billOutside)}
        />
        <DeprecatedRadioButtonField
          name="payerSetting"
          automationId="payer-pays"
          radioValue={Payer.CUSTOMER}
          labelText={intl.formatMessage(MESSAGES.billWith)}
        />
      </DeprecatedRadioGroupContainer>
      <div>
        <p className={Styles.feeInfo}>
          <FormattedMessage
            id="364d11f7-e442-4ba4-8d71-8486bca5f975"
            defaultMessage="Set your default notarization and seal fees (you can change these for individual transactions)"
          />
        </p>
        <div className={Styles.inputs}>
          <FormGroup fields={["notarizationFee"]} className={Styles.formRow}>
            <DeprecatedTextField
              id="notarizationFee"
              automationId="notarization-fee"
              name="notarizationFee"
              disabled={isOrgPayer}
              useStyledInput
              normalize={normalizeToNumber}
              label={intl.formatMessage(MESSAGES.notarizationFees)}
              className={Styles.input}
            />
            <FormGroupErrors fields={["notarizationFee"]} />
          </FormGroup>

          <FormGroup fields={["additionalSealFee"]} className={Styles.formRow}>
            <DeprecatedTextField
              id="additionalSealFee"
              automationId="additonal-seal-fee"
              name="additionalSealFee"
              disabled={isOrgPayer}
              useStyledInput
              normalize={normalizeToNumber}
              label={intl.formatMessage(MESSAGES.additionalSealFees)}
              className={Styles.input}
            />
            <FormGroupErrors fields={["additionalSealFee"]} />
          </FormGroup>
        </div>
      </div>
      <Button
        className={Styles.button}
        automationId="update-fees-button"
        isLoading={isUpdating}
        type="submit"
        buttonColor="action"
        variant="primary"
        disabled={!valid}
      >
        <FormattedMessage
          id="f01bc149-8abc-471d-be57-3ae317a658d9"
          defaultMessage="{isPaymentSpecified, select, true{Update} other{Set}} Payment Settings"
          values={{ isPaymentSpecified }}
        />
      </Button>
    </form>
  );
}

function UpdatePaymentSettingsContainer(props: InjectedProps) {
  const [activeOrganizationId] = useActiveOrganization();
  const { data, loading } = useQuery(OrganizationPaymentQuery, {
    variables: { organizationId: activeOrganizationId! },
  });
  if (loading) {
    return null;
  }
  const organization = data?.node;
  if (organization?.__typename !== "Organization") {
    throw new Error(`Expected Organization, got ${organization?.__typename}`);
  }
  return <UpdatePaymentSettings {...props} organization={organization} />;
}

export default compose(
  reduxForm<FormValues>({
    form: "updateFeesForm",
    validate: composeValidators(
      validatePresence({
        field: "notarizationFee",
        label: "Value equal to or greater than 0",
      }),
      validatePresence({
        field: "additionalSealFee",
        label: "Value equal to or greater than 0",
      }),
    ),
  }),
  getFormValues("updateFeesForm"),
)(UpdatePaymentSettingsContainer);
