import "common/notary/profile_wizard/section/index.scss";

import { useCallback, useMemo } from "react";
import { Route, useOutletContext } from "react-router-dom";
import classNames from "classnames";
import { useIntl } from "react-intl";

import { useMutation } from "util/graphql";
import StateRequiredEducation, {
  EDUCATION_PATH,
  stateEducationSection,
} from "common/notary/profile_wizard/section/state_required_education";
import type { MaybeUpdateUserFn } from "common/settingsv2/sidebar_settings/notary";
import CommissionAndRonInfo, {
  COMMISSION_PATH,
  commissionAndRonSection,
} from "common/notary/profile_wizard/section/commission_and_ron_info";
import InsuranceDetails, {
  INSURANCE_PATH,
  insuranceDetailsSection,
} from "common/notary/profile_wizard/section/insurance_details";
import DigitalCertificate, {
  DIGITAL_CERTIFICATE_PATH,
  certificateSection,
} from "common/notary/profile_wizard/section/digital_certificate";
import SignatureAndSeal, {
  SIGNATURE_SEAL_PATH,
  sigAndSealSection,
} from "common/notary/profile_wizard/section/signature_and_seal";
import PayoutOptions, {
  PAYOUT_PATH,
  payoutSection,
} from "common/notary/profile_wizard/section/payout";
import PersonalInformationSection, {
  PERSONAL_INFO_ROUTE,
  personalInfoSection,
} from "common/notary/profile_wizard/section/personal_information";
import TermsOfUseSection, { termsSection } from "common/notary/profile_wizard/section/terms_of_use";
import Footer from "common/settingsv2/sidebar_settings/notary/footer";
import { AuthenticationTypes } from "graphql_globals";
import ProofCertificate, {
  PROOF_CERTIFICATE_ROUTE,
  proofCertificateSection,
} from "common/notary/profile_wizard/section/digital_certificate/proof";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";
import IdentityVerification, {
  IDENTITY_VERIFICATION_ROUTE,
  identityVerificationSection,
} from "common/notary/profile_wizard/section/identity_verification";

import UpdateUserMutation from "../update_user_mutation.graphql";
import Styles from "../index.module.scss";
import UpdateNotaryProfileMutation from "../update_notary_profile_mutation.graphql";
import type { ProfileSettings_viewer_user as User } from "../index_query.graphql";

type UserContext = { user: User };

type StepProps = {
  user: User;
  activeStep?: Section | { id: "PayoutOptions" };
  onNext: MaybeUpdateUserFn;
};

type WrapperProps = {
  id: string;
};

type MaybeSection =
  | ReturnType<typeof personalInfoSection>
  | ReturnType<typeof commissionAndRonSection>
  | ReturnType<typeof certificateSection>
  | ReturnType<typeof insuranceDetailsSection>
  | ReturnType<typeof payoutSection>
  | ReturnType<typeof sigAndSealSection>
  | ReturnType<typeof stateEducationSection>
  | ReturnType<typeof termsSection>
  | ReturnType<typeof proofCertificateSection>
  | ReturnType<typeof identityVerificationSection>;
type Section = Exclude<MaybeSection, false>;

function useUpdateNotaryCb(user: User) {
  const updateNotaryProfileMutateFn = useMutation(UpdateNotaryProfileMutation);
  const updateUserMutateFn = useMutation(UpdateUserMutation);
  return useCallback<StepProps["onNext"]>(
    async (input) => {
      let updatedUser = user;
      if (input) {
        const { userPhoneNumber, countryCallingCode, ...updateNotaryInput } = input;

        // Phone number on personal information page is now updating phone number tied to user 8/12/24
        if (userPhoneNumber) {
          await updateUserMutateFn({
            variables: {
              input: {
                phoneNumber: countryCallingCode
                  ? countryCallingCode + userPhoneNumber
                  : userPhoneNumber,
              },
            },
          });
        }

        const response = await updateNotaryProfileMutateFn({
          variables: {
            input: {
              ...updateNotaryInput,
              id: user.notaryProfile!.id,
            },
          },
        });
        updatedUser = response.data!.updateNotaryProfile!.currentUser!;
      }
      return updatedUser;
    },
    [updateNotaryProfileMutateFn],
  );
}

function filterSections(maybeSection: MaybeSection): maybeSection is Section {
  return Boolean(maybeSection);
}

export function useSettingsSections(user: User, activeSectionId?: string) {
  const notaryProfile = user.notaryProfile!;
  const migrateExpiringNotaries = notaryProfile.usState.proofCertEnabled;
  const { sections, activeSection } = useMemo(() => {
    const lookup = new Set(notaryProfile.requirements);
    const sections = Object.freeze(
      [
        personalInfoSection(lookup, user),
        commissionAndRonSection(lookup, notaryProfile),
        insuranceDetailsSection(lookup, notaryProfile),
        certificateSection(lookup, notaryProfile, migrateExpiringNotaries),
        stateEducationSection(lookup, notaryProfile),
        sigAndSealSection(lookup, notaryProfile),
        identityVerificationSection(notaryProfile, migrateExpiringNotaries),
        proofCertificateSection(notaryProfile, migrateExpiringNotaries),
        payoutSection(user),
        termsSection(),
      ]
        .filter(filterSections)
        .map((section, index) =>
          Object.freeze({
            ...section,
            isActive: (!activeSectionId && index === 0) || activeSectionId === section.id,
          }),
        ),
    );
    const activeSection = sections.find((section) => section.isActive);
    return { sections, activeSection };
  }, [activeSectionId, user, notaryProfile]);
  return {
    sections,
    activeSection,
  };
}

function renderContent({ activeStep, user, onNext }: StepProps) {
  switch (activeStep?.id) {
    case "CommissionAndRonInfo":
      return (
        <CommissionAndRonInfo
          user={user}
          onNext={onNext}
          countyRequired={activeStep.countyRequired}
          notaryIdRequired={activeStep.notaryIdRequired}
          backgroundExpirationRequired={activeStep.backgroundExpirationRequired}
          renderFooter={(handleSubmit) => <Footer onSubmit={handleSubmit} />}
        />
      );
    case "InsuranceDetails":
      return (
        <InsuranceDetails
          user={user}
          onNext={onNext}
          renderFooter={(handleSubmit) => <Footer onSubmit={handleSubmit} />}
        />
      );
    case "DigitalCertificate":
      return (
        <DigitalCertificate
          user={user}
          onNext={onNext}
          renderFooter={(handleSubmit) => <Footer onSubmit={handleSubmit} />}
        />
      );
    case "SignatureAndSeal":
      return (
        <SignatureAndSeal
          user={user}
          onNext={onNext}
          renderFooter={(handleSubmit) => <Footer onSubmit={handleSubmit} />}
        />
      );
    case "StateEducation":
      return (
        <StateRequiredEducation
          user={user}
          onNext={onNext}
          proofRequired={activeStep.proofRequired}
          expirationDateRequired={activeStep.expirationDateRequired}
          renderFooter={(handleSubmit) => <Footer onSubmit={handleSubmit} />}
        />
      );
    case "PayoutOptions":
      return <PayoutOptions user={user} />;
    case "PersonalInfo":
      return (
        <PersonalInformationSection
          user={user}
          onNext={onNext}
          renderFooter={(handleSubmit) => <Footer onSubmit={handleSubmit} />}
          showPasswordUpdate={user.authenticationTypes.includes(AuthenticationTypes.PASSWORD)}
        />
      );
    case "TermsOfUse":
      return <TermsOfUseSection user={user} />;
    case "ProofCertificate":
      return <ProofCertificate user={user} isSettings />;
    case "IdentityVerification":
      return <IdentityVerification user={user} />;
    default:
      return null;
  }
}

function useNotaryProfilePageTitle(stepId: Section["id"] | undefined) {
  const docTitles = useDocumentTitles();
  return stepId ? docTitles[`settingsNotary${stepId}`] : docTitles.settingsNotaryDefault;
}

function SectionWrapper({ id }: WrapperProps) {
  const { user } = useOutletContext<UserContext>();

  const { sections } = useSettingsSections(user);
  const updateNotaryUser = useUpdateNotaryCb(user);
  const step = sections.find((section) => section.id === id);
  useA11y().useDocumentEntitler({
    title: useIntl().formatMessage(useNotaryProfilePageTitle(step?.id)),
  });
  return (
    <div className={classNames("NotaryProfileWizardStep", Styles.notaryProfileWizardStep)}>
      {renderContent({
        user,
        onNext: updateNotaryUser,
        activeStep: step,
      })}
    </div>
  );
}

export const NOTARY_SECTION_ROUTES = (
  <>
    <Route path={PERSONAL_INFO_ROUTE} element={<SectionWrapper id="PersonalInfo" />} />
    <Route path={COMMISSION_PATH} element={<SectionWrapper id="CommissionAndRonInfo" />} />
    <Route path={INSURANCE_PATH} element={<SectionWrapper id="InsuranceDetails" />} />
    <Route path={DIGITAL_CERTIFICATE_PATH} element={<SectionWrapper id="DigitalCertificate" />} />
    <Route path={SIGNATURE_SEAL_PATH} element={<SectionWrapper id="SignatureAndSeal" />} />
    <Route path={EDUCATION_PATH} element={<SectionWrapper id="StateEducation" />} />
    <Route path={PAYOUT_PATH} element={<SectionWrapper id="PayoutOptions" />} />
    <Route
      path={IDENTITY_VERIFICATION_ROUTE}
      element={<SectionWrapper id="IdentityVerification" />}
    />
    <Route path={PROOF_CERTIFICATE_ROUTE} element={<SectionWrapper id="ProofCertificate" />} />
  </>
);
