import { useEffect, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { reduxForm, type InjectedFormProps, SubmissionError } from "redux-form";
import { isPast } from "date-fns";

import {
  NotaryDocumentTypes,
  ValidationRequirements,
  NotaryProfileInvalidFields as InvalidFields,
} from "graphql_globals";
import { composeValidators } from "util/form";
import { b } from "util/html";
import { normalizeToNumber } from "util/normalize";
import { splitDate } from "util/date";
import { validatePresence, validateFutureDay, validateIf } from "validators/form";
import SubForm from "common/form/sub_form";
import SubFormSection from "common/form/sub_form/section";
import SubFormSectionDivider from "common/form/sub_form/section/divider";
import { DeprecatedFormRow } from "common/form/elements/row";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import { DeprecatedMultipartColumn } from "common/form/inputs/multipart/column";
import { DeprecatedMultipartRow } from "common/form/inputs/multipart/row";
import { DeprecatedTextField } from "common/form/fields/text";
import { DeprecatedMonthField } from "common/form/fields/month";
import { NotaryProfileWizardAssetUploader } from "common/notary/profile_wizard/upload";
import { openSupportChat } from "common/support/chat";
import { useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";
import type { FormError } from "errors/util";
import { customMessage } from "errors/form";
import { Paragraph } from "common/core/typography";
import AlertMessage from "common/core/alert_message";

import {
  type FileInfo,
  findDocumentLabel,
  getCommissionErrorMessage,
  getNnaErrorMessage,
  initializeNotaryDocumentField,
  isWisconsinAttorney,
  useChangeFileCallback,
} from "../section_utils";
import type {
  NotaryProfileWizardCommissionAndRonInfo as User,
  NotaryProfileWizardCommissionAndRonInfo_notaryProfile as NotaryProfile,
} from "./index_fragment.graphql";
import UpdateNnaInformationMutation from "./update_nna_information_mutation.graphql";
import {
  NNASection,
  hasNNASection,
  NNA_EXPIRED_ERROR_MESSAGE,
  NNA_MISSING_EXAM_OR_BACKGROUND_CHECK,
  useRefreshSubmit,
} from "./nna";
import Styles from "./index.module.scss";

export const COMMISSION_PATH = "commission";

type FormValues = {
  county: string | null;
  notaryId: string | null;
  commissionExpiryDay: string;
  commissionExpiryMonth: string;
  commissionExpiryYear: string;
  commissionKey: FileInfo;
  certificateOfAuthorizationKey: FileInfo;
  ronAuthorizationKey: FileInfo;
  nnaId: string | null;
  nnaTrainingExpirationDay: string;
  nnaTrainingExpirationMonth: string;
  nnaTrainingExpirationYear: string;
  backgroundCheckExpirationDay: string;
  backgroundCheckExpirationMonth: string;
  backgroundCheckExpirationYear: string;
};
type Input = {
  county: string | null;
  licenseExpiry: string | null;
  notaryDocuments: { keys: string[]; documentType: NotaryDocumentTypes }[];
  notaryId: string | null;
  nnaTrainingExpiry: string;
  backgroundCheckExpiry: string | null;
};
type Props = {
  user: User;
  onNext: (input: Input) => Promise<unknown>;
  countyRequired: boolean;
  notaryIdRequired: boolean;
  backgroundExpirationRequired: boolean;
  renderFooter: (handleSubmit: () => Promise<unknown>) => ReactNode;
};
type InnerProps = InjectedFormProps<FormValues, Props> & Props;

const PAPER_COMMISSION_LABEL = (
  <FormattedMessage
    id="9b81f65d-c5a1-47f4-a44f-a7dde360694f"
    defaultMessage="Paper Commission Document"
  />
);

const CERTIFICATE_OF_AUTHORIZATION = (
  <FormattedMessage
    id="fc0b7fd6-a559-4848-9c06-fa315c0c3305"
    defaultMessage="Certificate of Authorization Document"
  />
);

const RON_AUTH_LABEL = (
  <FormattedMessage
    id="0846c7f7-a9c8-4b0a-a8fd-56b8f440fbb2"
    defaultMessage="RON Proof Documentation"
  />
);

type CommissionAndRonInfoType =
  | {
      id: "CommissionAndRonInfo";
      countyRequired: boolean;
      notaryIdRequired: boolean;
      backgroundExpirationRequired: boolean;
      completed: boolean;
      route: typeof COMMISSION_PATH;
    }
  | false;

export function commissionAndRonSection(
  lookup: Set<ValidationRequirements>,
  notaryProfile: NotaryProfile,
): CommissionAndRonInfoType {
  const fields = [
    InvalidFields.MISSING_NOTARY_ID,
    InvalidFields.MISSING_COUNTY,
    InvalidFields.INVALID_LICENSE_EXPIRY,
    InvalidFields.INVALID_PAPER_COMMISSION_DOCUMENT,
    InvalidFields.INVALID_RON_AUTHORIZATION_DOCUMENT,
    InvalidFields.INVALID_PROOF_NOTIFY_COMMISSION_DOCUMENT,
  ];
  const completed = !fields.some((field) => notaryProfile.validation.invalidFields.includes(field));
  return {
    id: "CommissionAndRonInfo",
    countyRequired: lookup.has(ValidationRequirements.COMMISSION_COUNTY),
    notaryIdRequired: lookup.has(ValidationRequirements.COMMISSION_NUMBER),
    backgroundExpirationRequired: lookup.has(ValidationRequirements.BACKGROUND_CHECK_EXPIRATION),
    completed,
    route: COMMISSION_PATH,
  };
}

function CommissionAndRonInfo({
  user,
  countyRequired,
  notaryIdRequired,
  backgroundExpirationRequired,
  initialize,
  change,
  handleSubmit,
  onNext,
  renderFooter,
}: InnerProps) {
  const notaryProfile = user.notaryProfile!;
  const updateNnaInformationMutation = useMutation(UpdateNnaInformationMutation);
  const nnaAvailable = hasNNASection(notaryProfile);
  const serializeForm = (fv: FormValues) => {
    const notaryDocuments = [];
    if (typeof fv.commissionKey?.key === "string" && fv.commissionKey.key.length !== 0) {
      notaryDocuments.push({
        keys: [fv.commissionKey.key],
        name: fv.commissionKey.file?.name,
        documentType: NotaryDocumentTypes.PAPER_COMMISSION,
      });
    }

    if (
      typeof fv.ronAuthorizationKey?.key === "string" &&
      fv.ronAuthorizationKey.key.length !== 0
    ) {
      notaryDocuments.push({
        keys: [fv.ronAuthorizationKey.key],
        name: fv.ronAuthorizationKey.file?.name,
        documentType: NotaryDocumentTypes.PROOF_NOTIFY_COMMISSION,
      });
    }

    if (
      typeof fv.certificateOfAuthorizationKey?.key === "string" &&
      fv.certificateOfAuthorizationKey.key.length !== 0
    ) {
      notaryDocuments.push({
        keys: [fv.certificateOfAuthorizationKey.key],
        name: fv.certificateOfAuthorizationKey.file?.name,
        documentType: NotaryDocumentTypes.CERTIFICATE_OF_AUTHORIZATION,
      });
    }

    return onNext({
      county: countyRequired ? fv.county : null,
      licenseExpiry: isWisconsinAttorney(notaryProfile)
        ? null
        : `${fv.commissionExpiryYear}-${fv.commissionExpiryMonth}-${fv.commissionExpiryDay}`,
      notaryDocuments,
      notaryId: notaryIdRequired ? fv.notaryId : null,
      nnaTrainingExpiry: `${fv.nnaTrainingExpirationYear}-${fv.nnaTrainingExpirationMonth}-${fv.nnaTrainingExpirationDay}`,
      backgroundCheckExpiry: backgroundExpirationRequired
        ? `${fv.backgroundCheckExpirationYear}-${fv.backgroundCheckExpirationMonth}-${fv.backgroundCheckExpirationDay}`
        : null,
    })
      .then(() => {
        if (fv.nnaId && fv.nnaId !== notaryProfile.nnaId) {
          return updateNnaInformationMutation({
            variables: { input: { nnaId: String(fv.nnaId), notaryProfileId: notaryProfile.id } },
          });
        }
      })
      .then((result) => {
        const updatedNNAProfile = result?.data?.updateNotaryNnaInformation?.notaryProfile;
        if (!updatedNNAProfile) {
          return;
        }
        const { backgroundCheckExpiry, nnaTrainingExpiry } = updatedNNAProfile;

        const [
          backgroundCheckExpirationYear,
          backgroundCheckExpirationMonth,
          backgroundCheckExpirationDay,
        ] = splitDate(backgroundCheckExpiry);

        change("backgroundCheckExpirationYear", backgroundCheckExpirationYear);
        change("backgroundCheckExpirationMonth", backgroundCheckExpirationMonth);
        change("backgroundCheckExpirationDay", backgroundCheckExpirationDay);

        const [nnaTrainingExpirationYear, nnaTrainingExpirationMonth, nnaTrainingExpirationDay] =
          splitDate(nnaTrainingExpiry);

        change("nnaTrainingExpirationYear", nnaTrainingExpirationYear);
        change("nnaTrainingExpirationMonth", nnaTrainingExpirationMonth);
        change("nnaTrainingExpirationDay", nnaTrainingExpirationDay);
        if (!backgroundCheckExpiry || !nnaTrainingExpiry) {
          throw new SubmissionError<FormValues, FormError>({
            nnaId: customMessage({
              message: NNA_MISSING_EXAM_OR_BACKGROUND_CHECK,
            }),
          });
        } else if (isPast(new Date(backgroundCheckExpiry)) || isPast(new Date(nnaTrainingExpiry))) {
          throw new SubmissionError<FormValues, FormError>({
            nnaId: customMessage({
              message: NNA_EXPIRED_ERROR_MESSAGE,
            }),
          });
        }
      })
      .catch((error) => {
        const commissionNumberMessage =
          isGraphQLError(error) && getCommissionErrorMessage(error.message);
        const nnaErrorMessage = isGraphQLError(error) && getNnaErrorMessage(error.message);

        if (commissionNumberMessage) {
          throw new SubmissionError<FormValues, FormError>({
            notaryId: customMessage({
              message: commissionNumberMessage,
            }),
          });
        }
        if (nnaErrorMessage) {
          throw new SubmissionError<FormValues, FormError>({
            nnaId: customMessage({
              message: nnaErrorMessage,
            }),
          });
        }
        throw error;
      });
  };

  useEffect(() => {
    const {
      county,
      notaryId,
      traditionalCommissionExpiryDate,
      notaryDocuments,
      nnaTrainingExpiry,
      backgroundCheckExpiry,
    } = notaryProfile;
    const [commissionExpiryYear, commissionExpiryMonth, commissionExpiryDay] = splitDate(
      traditionalCommissionExpiryDate,
    );
    const [nnaTrainingExpirationYear, nnaTrainingExpirationMonth, nnaTrainingExpirationDay] =
      splitDate(nnaTrainingExpiry);
    const [
      backgroundCheckExpirationYear,
      backgroundCheckExpirationMonth,
      backgroundCheckExpirationDay,
    ] = splitDate(backgroundCheckExpiry);

    initialize({
      county: county || "",
      commissionKey: initializeNotaryDocumentField(
        notaryDocuments,
        NotaryDocumentTypes.PAPER_COMMISSION,
      ),
      commissionExpiryDay,
      commissionExpiryYear,
      commissionExpiryMonth,
      notaryId: notaryId || "",
      ronAuthorizationKey:
        initializeNotaryDocumentField(notaryDocuments, NotaryDocumentTypes.RON_AUTHORIZATION) ||
        initializeNotaryDocumentField(notaryDocuments, NotaryDocumentTypes.PROOF_NOTIFY_COMMISSION),
      nnaId: notaryProfile.nnaId,
      nnaTrainingExpirationDay,
      nnaTrainingExpirationYear,
      nnaTrainingExpirationMonth,
      backgroundCheckExpirationDay,
      backgroundCheckExpirationYear,
      backgroundCheckExpirationMonth,
    });
  }, []);

  useEffect(() => {
    if (user.notaryProfile?.nnaTrainingExpiry) {
      const [nnaTrainingExpirationYear, nnaTrainingExpirationMonth, nnaTrainingExpirationDay] =
        splitDate(user.notaryProfile.nnaTrainingExpiry);

      change("nnaTrainingExpirationYear", nnaTrainingExpirationYear);
      change("nnaTrainingExpirationMonth", nnaTrainingExpirationMonth);
      change("nnaTrainingExpirationDay", nnaTrainingExpirationDay);
    }
  }, [user.notaryProfile?.nnaTrainingExpiry]);

  useEffect(() => {
    if (user.notaryProfile?.backgroundCheckExpiry) {
      const [
        backgroundCheckExpirationYear,
        backgroundCheckExpirationMonth,
        backgroundCheckExpirationDay,
      ] = splitDate(user.notaryProfile.backgroundCheckExpiry);

      change("backgroundCheckExpirationYear", backgroundCheckExpirationYear);
      change("backgroundCheckExpirationMonth", backgroundCheckExpirationMonth);
      change("backgroundCheckExpirationDay", backgroundCheckExpirationDay);
    }
  }, [user.notaryProfile?.backgroundCheckExpiry]);

  const handleCommissionDocChange = useChangeFileCallback("commissionKey", change);

  const handleRonAuthChange = useChangeFileCallback("ronAuthorizationKey", change);
  const handleCertificateOfAuthChange = useChangeFileCallback(
    "certificateOfAuthorizationKey",
    change,
  );

  const refreshNnaData = useRefreshSubmit({
    change,
    formName: "notaryProfileWizardCommissionAndRonInfo",
    notaryProfileId: notaryProfile.id,
    notaryBackgroundCheckExpiry: notaryProfile.backgroundCheckExpiry,
    notaryNnaTrainingExpiration: notaryProfile.nnaTrainingExpiry,
  });

  const onCheatClick = () => {
    change("nnaId", "000000000");
  };

  return (
    <>
      <div>
        <FormattedMessage
          id="c5aa229e-96b9-4e4b-bd96-4c9693162fc3"
          defaultMessage="Commission Details"
          tagName="h3"
        />
        <SubForm>
          <FormattedMessage
            id="7f839081-9425-494a-aee1-77f1f4830e7c"
            defaultMessage="Commissioned State: <b>{state}</b>"
            values={{ state: notaryProfile.usState.name, b }}
            tagName="p"
          />
          <span className="subtext">
            <FormattedMessage
              id="19d32914-6458-4cba-90f6-9b1ae5dcd865"
              defaultMessage="(If this information is incorrect, please <a>reach out to support</a> to have it updated)"
              values={{
                a: (msg: ReactNode[]) => <a onClick={() => openSupportChat()}>{msg}</a>,
              }}
            />
          </span>
          <SubFormSection>
            {countyRequired && (
              <DeprecatedFormRow>
                <FormattedMessage
                  id="ca7dfc6a-4623-4406-ae3e-91bf7691f7f6"
                  defaultMessage="Commissioned County"
                  tagName="label"
                />
                <DeprecatedTextField
                  id="county"
                  name="county"
                  automationId="county"
                  useStyledInput
                />
                <FormGroupErrors fields={["county"]} />
              </DeprecatedFormRow>
            )}

            {notaryIdRequired && (
              <DeprecatedFormRow>
                <FormattedMessage
                  id="6b7ddab5-a812-4769-97ce-973f665be7d1"
                  defaultMessage="Commission Number"
                  tagName="label"
                />
                <DeprecatedTextField name="notaryId" automationId="notaryId" useStyledInput />
                <FormGroupErrors fields={["notaryId"]} />
              </DeprecatedFormRow>
            )}

            {!isWisconsinAttorney(notaryProfile) && (
              <FormGroup
                disableFormRowStyle
                fields={["commissionExpiryYear", "commissionExpiryMonth", "commissionExpiryDay"]}
              >
                <FormattedMessage
                  id="f26e1926-51e3-492b-a95c-e5c16a3b9f39"
                  defaultMessage="Traditional Commission Expiration Date"
                  tagName="label"
                />
                <DeprecatedMultipartRow>
                  <DeprecatedMultipartColumn width={6}>
                    <DeprecatedMonthField
                      name="commissionExpiryMonth"
                      useStyledInput
                      searchable={false}
                      clearable={false}
                      automationId="expiryMonth"
                    />
                  </DeprecatedMultipartColumn>
                  <DeprecatedMultipartColumn width={2}>
                    <DeprecatedTextField
                      name="commissionExpiryDay"
                      placeholder="DD"
                      normalize={normalizeToNumber}
                      maxLength="2"
                      useStyledInput
                      automationId="expiryDay"
                    />
                  </DeprecatedMultipartColumn>
                  <DeprecatedMultipartColumn width={4}>
                    <DeprecatedTextField
                      name="commissionExpiryYear"
                      placeholder="YYYY"
                      normalize={normalizeToNumber}
                      maxLength="4"
                      useStyledInput
                      automationId="expiryYear"
                    />
                  </DeprecatedMultipartColumn>
                </DeprecatedMultipartRow>
                <FormGroupErrors
                  fields={["commissionExpiryDay", "commissionExpiryMonth", "commissionExpiryYear"]}
                />
              </FormGroup>
            )}
          </SubFormSection>

          <SubFormSection>
            <FormattedMessage
              id="8ca11d58-e977-4a54-a8c3-26955a064737"
              defaultMessage="Upload a copy of your traditional commission"
              tagName="p"
            />
            <NotaryProfileWizardAssetUploader
              persistedValue={findDocumentLabel(
                notaryProfile.notaryDocuments,
                NotaryDocumentTypes.PAPER_COMMISSION,
                PAPER_COMMISSION_LABEL,
              )}
              onChange={handleCommissionDocChange}
            />
            <FormGroupErrors fields={["commissionKey"]} />
          </SubFormSection>
          {notaryProfile.requirements.includes(
            ValidationRequirements.CERTIFICATE_OF_AUTHORIZATION,
          ) && (
            <SubFormSection fullWidth>
              <Paragraph>
                <FormattedMessage
                  id="c1fe2fae-67e2-4261-9cdc-5c9e364cbcb4"
                  defaultMessage="Upload a copy of your certificate of authorization"
                />
              </Paragraph>
              <AlertMessage kind="info" className={Styles.warningBanner}>
                <FormattedMessage
                  id="01296a2a-b7a4-4830-8bc0-332cd48076f7"
                  defaultMessage="Upload this document for review by the Proof team. Your onboarding experience will resume within 1-2 business days, once your document has been approved."
                />
              </AlertMessage>
              <NotaryProfileWizardAssetUploader
                persistedValue={findDocumentLabel(
                  notaryProfile.notaryDocuments,
                  NotaryDocumentTypes.CERTIFICATE_OF_AUTHORIZATION,
                  CERTIFICATE_OF_AUTHORIZATION,
                )}
                onChange={handleCertificateOfAuthChange}
              />
              <FormGroupErrors fields={["certificateOfAuthorizationKey"]} />
            </SubFormSection>
          )}

          <SubFormSectionDivider />
          {nnaAvailable && (
            <NNASection
              notaryProfile={notaryProfile}
              refreshNnaData={notaryProfile.nnaId ? handleSubmit(refreshNnaData) : undefined}
              onCheatClick={onCheatClick}
            />
          )}

          <FormattedMessage
            id="298037f9-7d8c-464b-8834-ca29f0c3d442"
            defaultMessage="* Certain changes to your settings require you to update your commissioning office with your new information."
            tagName="p"
          />
          {!nnaAvailable && (
            <div>
              <FormattedMessage
                id="b233b1a0-d42b-4669-b4e7-57945403f4c8"
                defaultMessage="Upload proof that you updated your commissioning office with your new information"
                tagName="p"
              />
              <SubFormSection>
                <NotaryProfileWizardAssetUploader
                  persistedValue={
                    findDocumentLabel(
                      notaryProfile.notaryDocuments,
                      NotaryDocumentTypes.RON_AUTHORIZATION,
                      false,
                    ) ||
                    findDocumentLabel(
                      notaryProfile.notaryDocuments,
                      NotaryDocumentTypes.PROOF_NOTIFY_COMMISSION,
                      RON_AUTH_LABEL,
                    )
                  }
                  onChange={handleRonAuthChange}
                />
                <FormGroupErrors fields={["ronAuthorizationKey"]} />
              </SubFormSection>
            </div>
          )}
        </SubForm>
      </div>
      {renderFooter(handleSubmit(serializeForm))}
    </>
  );
}

export default reduxForm<FormValues, Props>({
  form: "notaryProfileWizardCommissionAndRonInfo",
  validate: (values, props) =>
    composeValidators(
      validateIf({
        field: "county",
        condition: () => props.countyRequired,
        validation: validatePresence({ field: "county", label: "County" }),
      }),
      validateIf({
        field: "notaryId",
        condition: () => props.notaryIdRequired,
        validation: validatePresence({ field: "notaryId", label: "Commission number" }),
      }),

      validateIf({
        condition: () => !values.ronAuthorizationKey,
        validation: validatePresence({
          field: "onlineCommissionKey",
          label: "Proof of online commission or proof that you updated your commissioning office",
        }),
      }),
      validatePresence({
        field: "commissionKey",
        label: "Uploaded copy of traditional commission",
      }),
      validateIf({
        field: "commissionExpiryDay",
        condition: () => !isWisconsinAttorney(props.user.notaryProfile!),
        validation: validatePresence({
          field: "commissionExpiryDay",
          label: "Commission expiration day",
        }),
      }),
      validateIf({
        field: "commissionExpiryMonth",
        condition: () => !isWisconsinAttorney(props.user.notaryProfile!),
        validation: validatePresence({
          field: "commissionExpiryMonth",
          label: "Commission expiration month",
        }),
      }),
      validateIf({
        field: "commissionExpiryYear",
        condition: () => !isWisconsinAttorney(props.user.notaryProfile!),
        validation: validatePresence({
          field: "commissionExpiryYear",
          label: "Commission expiration year",
        }),
      }),
      validateIf({
        field: "commissionExpiryYear",
        condition: () => !isWisconsinAttorney(props.user.notaryProfile!),
        validation: validateFutureDay({
          field: "commissionExpiryYear",
          label: "Commission expiration",
          monthField: "commissionExpiryMonth",
          dayField: "commissionExpiryDay",
          yearField: "commissionExpiryYear",
        }),
      }),
    )(values),
})(CommissionAndRonInfo);
