import { FormattedMessage } from "react-intl";

import { useForm } from "common/core/form";
import { useActiveOrganization } from "common/account/active_organization";
import { QueryWithLoading } from "util/graphql/query";
import { OrganizationApiKeyCompletionRequirement, OrganizationApiKeyPayer } from "graphql_globals";

import { CreateEasylinkLayout } from "../create";
import OrganizationSettingsQuery, {
  type OrganizationSettings_organization_Organization as Organization,
} from "../organization_settings.query.graphql";
import { DEFAULT_VERIFY_CONFIGURATION, VerifyPayer, type VerifyFormValues } from "./common";
import { NameInput } from "../common";

export function CreateVerifyEasylink() {
  const [activeOrganizationId] = useActiveOrganization();
  const form = useForm<VerifyFormValues>({
    defaultValues: {},
  });

  return (
    <QueryWithLoading
      query={OrganizationSettingsQuery}
      variables={{ organizationId: activeOrganizationId! }}
    >
      {({ data }) => {
        const organization = data!.organization as Organization;

        return (
          <CreateEasylinkLayout
            form={form}
            title={
              <FormattedMessage id="a1b0f216-089c-4719-af1d-b006709af9f7" defaultMessage="Verify" />
            }
            getSubmitVariables={(values) => ({
              input: {
                name: values.name,
                payer: OrganizationApiKeyPayer.ORGANIZATION,
                completionRequirement: OrganizationApiKeyCompletionRequirement.VERIFY,
                minSigners: 1,
                maxSigners: 1,
                organizationId: activeOrganizationId!,
                verifyToolConfiguration: DEFAULT_VERIFY_CONFIGURATION,
              },
            })}
          >
            <NameInput form={form} />
            <VerifyPayer organization={organization} form={form} />
          </CreateEasylinkLayout>
        );
      }}
    </QueryWithLoading>
  );
}
