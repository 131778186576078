import { FormattedMessage } from "react-intl";

import { SidebarTabButtonSublabel, SidebarTabLink } from "common/sidebar";

export const ROUTE = "templates-dashboard";

export function ToolsSidebarTemplatesLink({ count }: { count: number }) {
  return (
    <SidebarTabLink to={ROUTE}>
      <FormattedMessage
        id="d978c068-3668-49c8-806d-b39e6625d68a"
        defaultMessage="Document templates"
      />
      <SidebarTabButtonSublabel>{count}</SidebarTabButtonSublabel>
    </SidebarTabLink>
  );
}
