import { type ReactNode, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

import { EasylinkTypes } from "graphql_globals";
import { useQuery, useMutation } from "util/graphql";
import type { FieldValues, UseFormReturn } from "common/core/form";
import LoadingIndicator from "common/core/loading_indicator";

import { CreateSignerUploadEasylink } from "./signer_upload/create";
import { CreateDocumentsProvidedEasyLink } from "./documents_provided/create";
import { NavigateToEasylinkDashboardRoute, useNavigateToEasylinkDashboardRoute } from "./navigate";
import { COMMON_MESSAGES, useIdentifyEasylinksEnabled } from "./common";
import CreateEasylinkMutation, {
  type CreateEasylinkVariables,
  type CreateEasylink_createEasylink_easylink as Easylink,
} from "./create_easylink.mutation.graphql";
import { EasylinkShareModal } from "./share_modal";
import { FormPageLayout } from "../common";
import EasylinkCreateQuery from "./easylink_create.query.graphql";
import { CreateIdentifyEasylink } from "./identify/create";
import { CreateVerifyEasylink } from "./verify/create";

const SIGNER_UPLOAD_TYPE_PARAM = "signer-upload";
const TEMPLATE_BASED_TYPE_PARAM = "template-based";
const IDENTIFY_TYPE_PARAM = "identify";
const VERIFY_TYPE_PARAM = "verify";

export function getCreateEasylinkUrl(type: EasylinkTypes) {
  const baseUrl = "/easylinks/new?type=";
  switch (type) {
    case EasylinkTypes.SIGNER_UPLOAD:
      return `${baseUrl}${SIGNER_UPLOAD_TYPE_PARAM}`;
    case EasylinkTypes.DOCUMENTS_PROVIDED:
      return `${baseUrl}${TEMPLATE_BASED_TYPE_PARAM}`;
    case EasylinkTypes.IDENTIFY:
      return `${baseUrl}${IDENTIFY_TYPE_PARAM}`;
    case EasylinkTypes.VERIFY:
      return `${baseUrl}${VERIFY_TYPE_PARAM}`;
  }
}

export function EasylinkCreateForm() {
  const { data, loading } = useQuery(EasylinkCreateQuery);
  const [searchParams] = useSearchParams();
  const easylinkType = searchParams.get("type");
  const identifyEasylinksEnabled = useIdentifyEasylinksEnabled(data?.viewer.user?.notaryProfile);

  if (loading) {
    return <LoadingIndicator />;
  }

  const notaryProfile = data!.viewer.user?.notaryProfile || null;

  switch (easylinkType) {
    case SIGNER_UPLOAD_TYPE_PARAM:
      return <CreateSignerUploadEasylink notaryProfile={notaryProfile} />;
    case TEMPLATE_BASED_TYPE_PARAM:
      return <CreateDocumentsProvidedEasyLink notaryProfile={notaryProfile} />;
    case IDENTIFY_TYPE_PARAM:
      if (identifyEasylinksEnabled) {
        return <CreateIdentifyEasylink />;
      }
      return <NavigateToEasylinkDashboardRoute />;
    case VERIFY_TYPE_PARAM:
      return <CreateVerifyEasylink />;
    default:
      return <NavigateToEasylinkDashboardRoute />;
  }
}

export function CreateEasylinkLayout<FormValuesType extends FieldValues>({
  children,
  title,
  form,
  getSubmitVariables,
}: {
  children: ReactNode;
  title: ReactNode;
  form: UseFormReturn<FormValuesType>;
  getSubmitVariables: (values: FormValuesType) => CreateEasylinkVariables;
}) {
  const { handleSubmit, formState } = form;
  const [errorMessage, setErrorMessage] = useState<string>();
  const [showEasylinkShareModal, setShowEasylinkShareModal] = useState<Easylink>();
  const intl = useIntl();
  const navigateToEasylinkDashboardRoute = useNavigateToEasylinkDashboardRoute();

  const createEasylinkMutateFn = useMutation(CreateEasylinkMutation);

  return (
    <FormPageLayout
      heading={
        <FormattedMessage id="2a446b8b-96e1-49ab-b284-e4082ae1c170" defaultMessage="New EasyLink" />
      }
      title={title}
      loading={formState.isSubmitting}
      submitDisabled={Boolean(showEasylinkShareModal)}
      submitText={
        <FormattedMessage
          id="f3a011db-a932-45db-b312-ce3ec1534404"
          defaultMessage="Generate EasyLink"
        />
      }
      onSubmit={handleSubmit((values) => {
        setErrorMessage(undefined);
        createEasylinkMutateFn({
          variables: getSubmitVariables(values),
        })
          .then((result) => {
            setShowEasylinkShareModal(result.data!.createEasylink!.easylink);
          })
          .catch(() => {
            setErrorMessage(intl.formatMessage(COMMON_MESSAGES.ERROR_MESSAGE));
          });
      })}
      cancelText={
        <FormattedMessage
          id="5663051d-f2ba-40a7-b7d5-4b8b58b47a5d"
          defaultMessage="Cancel and return to dashboard"
        />
      }
      onCancel={() => {
        navigateToEasylinkDashboardRoute({ restorePreviousView: true });
      }}
      errorMessage={errorMessage}
    >
      {children}
      {showEasylinkShareModal && (
        <EasylinkShareModal
          easylink={showEasylinkShareModal}
          onDone={() => {
            navigateToEasylinkDashboardRoute();
          }}
          isNew
        />
      )}
    </FormPageLayout>
  );
}
