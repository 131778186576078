import { FormattedMessage } from "react-intl";
import { Navigate, Route, Routes } from "react-router-dom";

import { SidebarV2, SidebarSectionHeader } from "common/sidebar";
import { useHeadingDetails } from "common/settingsv2/common";
import { usePermissions } from "common/core/current_user_role";
import { Feature } from "graphql_globals";

import {
  OrganizationSettings,
  SettingsSidebarOrgLink,
  ORG_ROUTE,
} from "../sidebar_settings/organization";
import {
  OrganizationOverview,
  OrganizationOverviewTab,
  ORG_OVERVIEW_ROUTE,
} from "../sidebar_settings/organization/overview";
import {
  NotaryBackgroundTab,
  NotaryBackgroundSettings,
  NOTARY_BG_ROUTE,
  useIsOrganizationBackgroundSettingsEnabled,
} from "../sidebar_settings/brand/settings/notary_background";
import {
  BillingSettings,
  SettingsSidebarBillingLink,
  BILLING_ROUTE,
} from "../sidebar_settings/billing";
import {
  PlanDetails,
  PlanDetailsTab,
  BILLING_PLAN_DETAILS_ROUTE,
} from "../sidebar_settings/billing/plan_details";
import {
  PaymentSettings,
  PaymentSettingsTab,
  BILLING_PAYMENT_SETTINGS_ROUTE,
} from "../sidebar_settings/billing/payment_settings";
import {
  BillingContact,
  BillingContactTab,
  BILLING_BILLING_CONTACT_ROUTE,
} from "../sidebar_settings/billing/billing_contact";
import {
  RecentPayments,
  RecentPaymentsTab,
  BILLING_RECENT_PAYMENTS_ROUTE,
} from "../sidebar_settings/billing/recent_payments";
import {
  TransactionSettings,
  SettingsSidebarTransactionsLink,
  TRANSACTIONS_ROUTE,
} from "../sidebar_settings/transaction";
import {
  SecuritySettings,
  SettingsSidebarSecurityLink,
  SECURITY_ROUTE,
} from "../sidebar_settings/security";
import LoginSecurity from "../sidebar_settings/security/login_security";
import {
  SignerSettings,
  SignerSettingsTab,
  TRANSACTIONS_SIGNER_SETTINGS_ROUTE,
} from "../sidebar_settings/transaction/signer_settings";
import {
  TemplateSettings,
  TemplateSettingsTab,
  TRANSACTIONS_TEMPLATE_SETTINGS_ROUTE,
} from "../sidebar_settings/transaction/template_settings";
import {
  NotaryInstructions,
  NotaryInstructionsTab,
  TRANSACTIONS_NOTARY_INSTRUCTIONS_ROUTE,
} from "../sidebar_settings/transaction/notary_instructions";
import { ProfileSettings, PROFILE_ROUTE } from "../sidebar_settings/profile";
import {
  ProfileOverview,
  ProfileOverviewTab,
  PROFILE_OVERVIEW_ROUTE,
} from "../sidebar_settings/profile/overview";
import {
  PersonalDetails,
  PersonalDetailsTab,
  PROFILE_PERSONAL_DETAILS_ROUTE,
} from "../sidebar_settings/profile/personal_details";
import {
  PersonalPayment,
  PersonalPaymentTab,
  PROFILE_PERSONAL_PAYMENTS_ROUTE,
} from "../sidebar_settings/profile/personal_payment";
import { TermsSettings, TERMS_ROUTE } from "../sidebar_settings/terms";
import { TermsAndConditions } from "../sidebar_settings/terms/terms";
import {
  BrandSettings as BrandSettingsWrapper,
  SettingsSidebarBrandCustomizationLink,
  BRAND_SETTINGS_ROOT_PREFIX,
} from "../sidebar_settings/brand";
import { BrandSettings, BRAND_SETTINGS_MAIN_ROUTE } from "../sidebar_settings/brand/settings";
import {
  LogoAndHeaderTab,
  LogoAndHeaderBrandSettings,
  LOGO_HEADER_BRAND_ROUTE,
} from "../sidebar_settings/brand/settings/logo_and_header";
import {
  BrandColorsTab,
  BrandColorsSettings,
  BRAND_COLORS_ROUTE,
} from "../sidebar_settings/brand/settings/brand_colors";
import {
  API_KEYS_SETTINGS_ROOT_PREFIX,
  SettingsSidebarApiKeysLink,
  ApiKeys,
} from "../sidebar_settings/api_keys";
import CustomizeLandingPage, {
  BRAND_SETTINGS_CUSTOMIZE_LANDING_ROUTE,
  CustomizeLandingPageTab,
} from "../sidebar_settings/brand/customization/landing_page";

function BusinessAdminSidebar(props: { notaryMissingPayment?: boolean }) {
  const { gateApi, orgName, featureList } = useHeadingDetails();
  const { hasPermissionFor } = usePermissions();

  return (
    <SidebarV2
      title={
        <FormattedMessage id="afa6c0e6-37c1-4287-94a4-7ddc592bee3f" defaultMessage="Settings" />
      }
    >
      <SidebarSectionHeader title={orgName} />
      <SettingsSidebarOrgLink />
      <SettingsSidebarBillingLink notaryMissingPayment={props.notaryMissingPayment} />
      <SettingsSidebarTransactionsLink />
      <SettingsSidebarSecurityLink />
      <SettingsSidebarBrandCustomizationLink />
      {featureList.includes(Feature.PARTNER_API) &&
        featureList.includes(Feature.API_KEY_MANAGEMENT) &&
        hasPermissionFor("viewOrganizationApiKeys") && (
          <SettingsSidebarApiKeysLink showUpgradeBadge={gateApi} />
        )}
    </SidebarV2>
  );
}

export default function BusinessAdminSettings(props: {
  organizationFeatureList: Feature[];
  isNotaryMissingPayment: boolean;
  showApi: boolean;
}) {
  const { isNotaryMissingPayment, organizationFeatureList, showApi } = props;
  const isOrgNotaryBackgroundsEnabled =
    useIsOrganizationBackgroundSettingsEnabled(organizationFeatureList);
  const { hasPermissionFor } = usePermissions();
  return (
    <>
      <BusinessAdminSidebar notaryMissingPayment={isNotaryMissingPayment} />
      <Routes>
        <Route
          path={ORG_ROUTE}
          element={
            <OrganizationSettings
              tabs={
                <>
                  <OrganizationOverviewTab />
                </>
              }
            />
          }
        >
          <Route path={ORG_OVERVIEW_ROUTE} element={<OrganizationOverview />} />
          <Route index element={<Navigate to={ORG_OVERVIEW_ROUTE} replace />} />
        </Route>

        <Route
          path={BILLING_ROUTE}
          element={
            <BillingSettings
              tabs={
                <>
                  <PlanDetailsTab />
                  <PaymentSettingsTab notaryMissingPayment={isNotaryMissingPayment} />
                  <BillingContactTab />
                  <RecentPaymentsTab />
                </>
              }
            />
          }
        >
          <Route path={BILLING_PLAN_DETAILS_ROUTE} element={<PlanDetails />} />
          {/* NSTs/ODNs will always be admins of their business orgs and should
          be able to access their payment settings in org settings */}
          <Route
            path={BILLING_PAYMENT_SETTINGS_ROUTE}
            element={<PaymentSettings showNotaryPaymentSettings />}
          />
          <Route path={BILLING_BILLING_CONTACT_ROUTE} element={<BillingContact />} />
          <Route path={BILLING_RECENT_PAYMENTS_ROUTE} element={<RecentPayments />} />
          <Route index element={<Navigate to={BILLING_PLAN_DETAILS_ROUTE} replace />} />
        </Route>

        <Route
          path={TRANSACTIONS_ROUTE}
          element={
            <TransactionSettings
              tabs={
                <>
                  <SignerSettingsTab />
                  <NotaryInstructionsTab />
                  <TemplateSettingsTab />
                </>
              }
            />
          }
        >
          <Route path={TRANSACTIONS_SIGNER_SETTINGS_ROUTE} element={<SignerSettings />} />
          <Route path={TRANSACTIONS_NOTARY_INSTRUCTIONS_ROUTE} element={<NotaryInstructions />} />
          <Route path={TRANSACTIONS_TEMPLATE_SETTINGS_ROUTE} element={<TemplateSettings />} />
          <Route index element={<Navigate to={TRANSACTIONS_SIGNER_SETTINGS_ROUTE} replace />} />
        </Route>

        <Route path={SECURITY_ROUTE} element={<SecuritySettings />}>
          <Route index element={<LoginSecurity />} />
        </Route>

        <Route path={BRAND_SETTINGS_ROOT_PREFIX} element={<BrandSettingsWrapper />}>
          <Route
            path={BRAND_SETTINGS_MAIN_ROUTE}
            element={
              <BrandSettings
                tabs={
                  <>
                    <LogoAndHeaderTab />
                    <BrandColorsTab />
                    <CustomizeLandingPageTab />
                    {isOrgNotaryBackgroundsEnabled && <NotaryBackgroundTab />}
                  </>
                }
              />
            }
          >
            <Route path={LOGO_HEADER_BRAND_ROUTE} element={<LogoAndHeaderBrandSettings />} />
            <Route path={BRAND_COLORS_ROUTE} element={<BrandColorsSettings />} />
            <Route
              path={BRAND_SETTINGS_CUSTOMIZE_LANDING_ROUTE}
              element={<CustomizeLandingPage />}
            />
            {isOrgNotaryBackgroundsEnabled && (
              <Route path={NOTARY_BG_ROUTE} element={<NotaryBackgroundSettings />} />
            )}
            <Route index element={<Navigate to={LOGO_HEADER_BRAND_ROUTE} replace />} />
          </Route>
          <Route index element={<Navigate replace to={BRAND_SETTINGS_MAIN_ROUTE} />} />
        </Route>

        <Route
          path={API_KEYS_SETTINGS_ROOT_PREFIX}
          element={
            hasPermissionFor("viewOrganizationApiKeys") ? (
              <ApiKeys showApi={showApi} />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />

        {/* REAL-8187 remove user-related paths with ia-nav release  */}
        <Route
          path={PROFILE_ROUTE}
          element={
            <ProfileSettings
              tabs={
                <>
                  <ProfileOverviewTab />
                  <PersonalDetailsTab />
                  <PersonalPaymentTab />
                </>
              }
            />
          }
        >
          <Route path={PROFILE_OVERVIEW_ROUTE} element={<ProfileOverview />} />
          <Route path={PROFILE_PERSONAL_DETAILS_ROUTE} element={<PersonalDetails />} />
          <Route path={PROFILE_PERSONAL_PAYMENTS_ROUTE} element={<PersonalPayment />} />
          <Route index element={<Navigate to={PROFILE_OVERVIEW_ROUTE} replace />} />
        </Route>

        <Route path={TERMS_ROUTE} element={<TermsSettings />}>
          <Route index element={<TermsAndConditions />} />
        </Route>

        <Route index element={<Navigate replace to={`${ORG_ROUTE}/${ORG_OVERVIEW_ROUTE}`} />} />
      </Routes>
    </>
  );
}
