import { DeprecatedSelectField } from "common/form/fields/select";
import { MONTHS } from "constants/months";

/** @deprecated - please use components in common/core/form */
export function DeprecatedMonthField(props) {
  return <DeprecatedSelectField {...props} />;
}

DeprecatedMonthField.defaultProps = {
  items: MONTHS,
  searchable: true,
  placeholder: "Month",
};
