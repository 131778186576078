import { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const noop = () => {};

/** @deprecated - please use components in common/core/form */
export class DeprecatedRadioItem extends Component {
  static displayName = "RadioItem";

  static propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    selectedValue: PropTypes.string,
    onChange: PropTypes.func,
  };

  handleClick = () => {
    const { value, onChange } = this.props;
    onChange(value);
  };

  render() {
    const { value, selectedValue } = this.props;
    const selected = value === selectedValue;

    const cx = classnames("RadioItem", {
      selected,
    });

    return (
      <div className={cx}>
        <input type="radio" onChange={noop} checked={selected} />
        <div className="RadioItem--radio-circle-container" onClick={this.handleClick}>
          <div className="RadioItem--radio-circle" />
        </div>
        <div className="RadioItem--content" onClick={this.handleClick} data-automation-id={value}>
          {this.props.label}
        </div>
      </div>
    );
  }
}
