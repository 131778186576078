import { useIntl, defineMessages, FormattedMessage } from "react-intl";

import { DeprecatedTextField } from "common/form/fields/text";
import Tooltip from "common/core/tooltip";
import Icon from "common/core/icon";
import { DeprecatedFileUpload } from "common/form/inputs/file_upload";
import { simpleAssetUpload } from "util/uploader";
import { CardHeading, CardSection, CardText } from "common/core/card";
import FormGroupErrors from "common/form/group_errors";
import Button from "common/core/button";

import Styles from "./index.module.scss";

export type FormValues = {
  metadataUrl: string | null;
  metadataS3Key: string | null;
  ssoTargetUrl: string | null;
  entityId: string | null;
  s3Key: string | null;
};

const noop = () => {};

const MESSAGES = defineMessages({
  metadataUrl: {
    id: "ad2c98ba-7fef-44ab-a9b7-17dc6c323677",
    defaultMessage: "Metadata URL",
  },

  metadataUrlTooltip: {
    id: "15e680a3-58b1-40f3-b680-29997db3cc89",
    defaultMessage:
      "A publicly-accessible URL for your SAML metadata. It will be requested once per login.",
  },

  metadataUrlTriggerLabel: {
    id: "57d5000e-e437-4960-82d7-94c02f20b084",
    defaultMessage: "More details about the metadata URL",
  },

  metadataUpload: {
    id: "0ba60453-bcfc-4dfe-822c-cddc1e25c6ed",
    defaultMessage: "Metadata Upload (.xml file)",
  },

  metadataUploadTooltip: {
    id: "7f5db1e7-4593-4830-9649-69b5c1c90040",
    defaultMessage:
      "If your SAML metadata is not accessible via a URL, you may upload a copy of it here.",
  },

  metadataUploadTriggerLabel: {
    id: "a0ad0185-8331-41ae-a051-beb79f937c8a",
    defaultMessage: "More details about uploading",
  },

  targetUrl: {
    id: "f0e10460-af73-4a8c-a324-6f01f99426fe",
    defaultMessage: "Target URL",
  },

  targetUrlTooltip: {
    id: "8862a638-dcb6-46bb-bf54-8de0f990153f",
    defaultMessage: "The IdP URL that will receive SAML requests from Notarize",
  },

  targetUrlTriggerLabel: {
    id: "147f1e83-c938-4ee7-a654-9b9a2bf88e81",
    defaultMessage: "More details about the IdP URL",
  },

  entityId: {
    id: "1a81af2c-8f4a-4501-b376-22af695d25f5",
    defaultMessage: "Entity ID",
  },

  entityIdTooltip: {
    id: "8ed95b80-032e-44cd-8d94-585a65450a58",
    defaultMessage: "The IdP issuer entity ID, a unique string, often a URL",
  },

  entityIdTriggerLabel: {
    id: "8cb086a5-fd75-4c0e-a1c9-c93f088a58f1",
    defaultMessage: "More details about the IdP issuer ID",
  },
  certificateUpload: {
    id: "e540d0ae-cf82-4804-8e18-a0c7ab6bb21f",
    defaultMessage: "X.509 Certificate Upload (.pem file)",
  },
});

export function SamlProvider({
  change,
  reactivateSettings,
  active,
}: {
  change: (field: string, value: string | null) => void;
  reactivateSettings: () => void;
  active: boolean;
}) {
  const intl = useIntl();

  const uploadMetadata = (file: File) =>
    simpleAssetUpload({ asset: file, type: "saml_metadata" }).then((key) =>
      change("metadataS3Key", key),
    );

  const uploadCertificate = (file: File) =>
    simpleAssetUpload({ asset: file, type: "saml_certificates" }).then((key) =>
      change("s3Key", key),
    );

  const deleteMetadata = () => {
    change("metadataS3Key", null);
  };

  const deleteCertificate = () => {
    change("s3Key", null);
  };

  return (
    <>
      {!active && (
        <div className={Styles.reactivate}>
          <CardSection>
            <CardHeading>
              <FormattedMessage
                id="66006e52-217a-4cd5-a09d-4951bba6433a"
                defaultMessage="* Settings Inactive"
              />
            </CardHeading>
            <Button buttonColor="action" variant="primary" onClick={reactivateSettings}>
              <FormattedMessage
                id="c34965ae-16fc-41b8-ab34-99d249d88bea"
                defaultMessage="Reactivate Existing Settings"
              />
            </Button>
          </CardSection>
        </div>
      )}

      <CardSection>
        <CardHeading>
          <FormattedMessage
            id="63d45239-961c-4497-9be0-cd280ff99125"
            defaultMessage="Provide a metadata URL or file, to enable SAML single sign-on."
          />
        </CardHeading>
      </CardSection>

      {/* Metadata URL */}
      <CardSection>
        <CardHeading>
          {intl.formatMessage(MESSAGES.metadataUrl)}{" "}
          <Tooltip
            target={<Icon className="icon" name="question" />}
            placement="top"
            triggerButtonLabel={intl.formatMessage(MESSAGES.metadataUrlTriggerLabel)}
          >
            {intl.formatMessage(MESSAGES.metadataUrlTooltip)}
          </Tooltip>
        </CardHeading>
        <CardText key="metadataUrl">
          <DeprecatedTextField
            useStyledInput
            placeholderAsLabel
            name="metadataUrl"
            automationId="saml-metadata-url"
            type="url"
          />
          <FormGroupErrors fields={["metadataUrl"]} />
        </CardText>
      </CardSection>

      {/* Metadata Upload */}
      <CardSection>
        <CardHeading>
          {intl.formatMessage(MESSAGES.metadataUpload)}{" "}
          <Tooltip
            target={<Icon className="icon" name="question" />}
            placement="top"
            triggerButtonLabel={intl.formatMessage(MESSAGES.metadataUploadTriggerLabel)}
          >
            {intl.formatMessage(MESSAGES.metadataUploadTooltip)}
          </Tooltip>
        </CardHeading>
        <CardText key="metadataS3Key">
          <DeprecatedFileUpload
            removeUpload={deleteMetadata}
            performUpload={uploadMetadata}
            acceptedFileTypes={{ "text/xml": "XML", "application/xml": "XML" }}
            filesizeLimit={1048576}
            hideLabel={false}
            onBlur={noop}
            onChange={noop}
            onSuccess={noop}
            onFailure={noop}
          />
        </CardText>
      </CardSection>

      <CardSection>
        <CardHeading>
          <FormattedMessage
            id="e73b6dce-8060-49de-8728-fd8dcf6b8c36"
            defaultMessage="If you don't have metadata, provide these fields instead:"
          />
        </CardHeading>
      </CardSection>

      {/* Manual config */}
      <CardSection>
        <CardHeading>
          {intl.formatMessage(MESSAGES.targetUrl)}{" "}
          <Tooltip
            target={<Icon className="icon" name="question" />}
            placement="top"
            triggerButtonLabel={intl.formatMessage(MESSAGES.targetUrlTriggerLabel)}
          >
            {intl.formatMessage(MESSAGES.targetUrlTooltip)}
          </Tooltip>
        </CardHeading>
        <CardText key="ssoTargetUrl">
          <DeprecatedTextField
            useStyledInput
            placeholderAsLabel
            name="ssoTargetUrl"
            automationId="saml-sso-target-url"
            type="url"
          />
          <FormGroupErrors fields={["targetUrl"]} />
        </CardText>
      </CardSection>

      <CardSection>
        <CardHeading>
          {intl.formatMessage(MESSAGES.entityId)}{" "}
          <Tooltip
            target={<Icon className="icon" name="question" />}
            placement="top"
            triggerButtonLabel={intl.formatMessage(MESSAGES.entityIdTriggerLabel)}
          >
            {intl.formatMessage(MESSAGES.entityIdTooltip)}
          </Tooltip>
        </CardHeading>
        <CardText key="entityId">
          <DeprecatedTextField
            useStyledInput
            placeholderAsLabel
            name="entityId"
            automationId="saml-entity-id"
          />
          <FormGroupErrors fields={["entityId"]} />
        </CardText>
      </CardSection>

      <CardSection>
        <CardHeading>{intl.formatMessage(MESSAGES.certificateUpload)} </CardHeading>
        <CardText key="s3Key">
          <DeprecatedFileUpload
            removeUpload={deleteCertificate}
            performUpload={uploadCertificate}
            acceptedFileTypes={{
              "application/x-x509-ca-cert": "PEM",
              "application/x-pem-file": "PEM",
              ".pem": "PEM",
            }}
            filesizeLimit={16384}
            hideLabel={false}
            onBlur={noop}
            onChange={noop}
            onSuccess={noop}
            onFailure={noop}
          />
        </CardText>
      </CardSection>
    </>
  );
}
