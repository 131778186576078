import { Outlet } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useEffect, useState, type ReactNode } from "react";

import { useActiveOrganization } from "common/account/active_organization";
import { SidebarTabLink } from "common/sidebar";
import { SettingsHeader } from "common/settingsv2/common";
import LoadingIndicator from "common/core/loading_indicator";
import { NetworkStatus, useQuery, useMutation } from "util/graphql";

import { BRAND_SETTINGS_ROOT_PREFIX } from "..";
import OrganizationBrandSettingsQuery, {
  type OrganizationBrandSettings,
  type OrganizationBrandSettingsVariables,
} from "./index.query.graphql";
import CreateOrganizationBrandMutation from "./create_organization_brand.mutation.graphql";

export const BRAND_SETTINGS_MAIN_ROUTE = "settings";

const TITLE_CUSTOMIZATION = (
  <FormattedMessage
    id="dc938cde-f01d-467d-95ac-f53d064f6b25"
    defaultMessage="Brand customization"
  />
);

export function SettingsSidebarBrandSettingsLink() {
  return (
    <SidebarTabLink
      to={`${BRAND_SETTINGS_ROOT_PREFIX}/${BRAND_SETTINGS_MAIN_ROUTE}`}
      isSubItem={false}
    >
      {TITLE_CUSTOMIZATION}
    </SidebarTabLink>
  );
}

export function BrandSettings({ tabs }: { tabs: ReactNode }) {
  const [activeOrganizationId] = useActiveOrganization();
  const [gettingOrCreatingOrganizationBrand, setGettingOrCreatingOrganizationBrand] =
    useState(true);
  const organizationId = activeOrganizationId!;

  const { data, networkStatus } = useQuery(OrganizationBrandSettingsQuery, {
    variables: { organizationId },
  });
  // using networkStatus to only show loading screen on initial load but not on any refetches
  const loading = networkStatus === NetworkStatus.loading;

  const createOrganizationBrand = useMutation(CreateOrganizationBrandMutation);

  useEffect(() => {
    if (
      !loading &&
      data?.organization?.__typename === "Organization" &&
      !data.organization.organizationBrand
    ) {
      createOrganizationBrand({
        variables: {
          input: {
            organizationId,
          },
        },
        update(cacheProxy, { data }) {
          const result = cacheProxy.readQuery<
            OrganizationBrandSettings,
            OrganizationBrandSettingsVariables
          >({
            query: OrganizationBrandSettingsQuery,
            variables: { organizationId },
          })!;
          const { organization } = result;
          if (organization?.__typename !== "Organization") {
            throw new Error(`Expected Organization, got ${organization?.__typename}.`);
          }
          if (!data?.createOrganizationBrand?.organizationBrand) {
            throw new Error("Organization brand failed to be created.");
          }
          const newNode = {
            ...organization,
            organizationBrand: data.createOrganizationBrand.organizationBrand,
          };
          cacheProxy.writeQuery({
            query: OrganizationBrandSettingsQuery,
            variables: { organizationId },
            data: { ...result, organization: newNode },
          });
        },
      }).then(() => {
        setGettingOrCreatingOrganizationBrand(false);
      });
    } else if (
      !loading &&
      data?.organization?.__typename === "Organization" &&
      data.organization.organizationBrand
    ) {
      setGettingOrCreatingOrganizationBrand(false);
    }
  }, [organizationId, data, loading]);

  if (loading || gettingOrCreatingOrganizationBrand) {
    return <LoadingIndicator />;
  }

  const organization = data ? data.organization! : null;
  if (organization && organization.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${organization.__typename}.`);
  }

  if (!organization) {
    throw new Error(`Expected organization, got nothing.`);
  }

  return (
    <>
      <SettingsHeader title={TITLE_CUSTOMIZATION} tabs={tabs} />
      <Outlet />
    </>
  );
}
