import { FormattedMessage } from "react-intl";

import { SidebarTabButtonSublabel, SidebarTabLink } from "common/sidebar";

export const ROUTE = "easylinks";

export function ToolsSidebarEasylinksLink({ count }: { count: number }) {
  return (
    <SidebarTabLink to={ROUTE}>
      <FormattedMessage id="cc3ba7a1-ad9d-46c9-a86e-03899da65d17" defaultMessage="EasyLinks" />
      <SidebarTabButtonSublabel>{count}</SidebarTabButtonSublabel>
    </SidebarTabLink>
  );
}
