import { memo, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { useMatch, useNavigate } from "react-router-dom";

import {
  NotaryActivationStatus,
  NotaryCapacityType,
  NotaryComplianceStatuses,
} from "graphql_globals";
import {
  NOTARY_PROFILE_ROUTE,
  useNotaryAccountStatus,
} from "common/settingsv2/sidebar_settings/notary";
import type { ProofFrame_viewer_user as User } from "common/proof_frame/index.query.graphql";
import AlertMessage from "common/core/alert_message";
import AppSubdomains, { type ProofPortal } from "constants/app_subdomains";
import { redirectToSubdomain } from "util/application_redirect";

type Props = {
  portal: ProofPortal;
  user: User;
};

type NotaryProfileAlert = {
  icon: "doc-warning" | "warning";
  kind: "danger" | "warning";
  message: ReactNode;
};

const ORG_BILLING_SETTINGS_PATH = "/settings/billing/payment-settings";

function ProofFrameNotaryProfileBanner({ portal, user }: Props) {
  const navigate = useNavigate();
  const { capacities, complianceStatus } = user.notaryProfile!;
  const {
    disabled,
    incompleteOnboarding,
    incompleteProfile,
    nstWithMissingPayment,
    odnWithMissingNNA,
    odnWaiting,
    ihnDisabled,
    notaryRequiresReapproval,
    pausedOnboarding,
  } = useNotaryAccountStatus(user);

  // Don't show banner in onboarding
  const isOnboarding = useMatch({ path: "/settings/notary/onboarding/", end: false });
  if (isOnboarding && !pausedOnboarding) {
    return null;
  }

  const isSignerPortal = portal === "app";
  const handleClick = (setting: string) => {
    // ODN/NST payment settings are in the Organization settings in the business app
    if (setting === ORG_BILLING_SETTINGS_PATH) {
      isSignerPortal ? redirectToSubdomain(AppSubdomains.business, setting) : navigate(setting);
    } else {
      const path = `/settings/${NOTARY_PROFILE_ROUTE}/${setting}`;
      isSignerPortal
        ? navigate(path)
        : redirectToSubdomain(AppSubdomains.customer, path, { newTab: true });
    }
  };

  let alert: NotaryProfileAlert | null = null;
  // Order below matters
  if (disabled) {
    alert = {
      icon: "doc-warning",
      kind: "danger",
      message: (
        <FormattedMessage
          id="a2a0661d-c948-4372-96c2-b387a246d0a2"
          defaultMessage="Your account has been deactivated by an administrator."
        />
      ),
    };
  } else if (pausedOnboarding && isOnboarding) {
    alert = {
      icon: "doc-warning",
      kind: "danger",
      message: (
        <FormattedMessage
          id="f20d6f84-3c27-40a3-bcd5-5dba2f8d5e31"
          defaultMessage="Your Certificate of Authorization must be reviewed. You cannot edit your profile until it has been reviewed."
        />
      ),
    };
  } else if (pausedOnboarding) {
    alert = {
      icon: "warning",
      kind: "warning",
      message: (
        <FormattedMessage
          id="97030459-3503-4392-9720-a140bdc5946f"
          defaultMessage="Your Certificate of Authorization must be reviewed. Our team will review your information and contact you within 1 to 2 business days."
        />
      ),
    };
  } else if (incompleteOnboarding) {
    alert = null; // handled by `src/common/banners/onboarding_banner/index.tsx`
  } else if (nstWithMissingPayment) {
    alert = {
      icon: "doc-warning",
      kind: "danger",
      message: (
        <FormattedMessage
          id="f20d6f84-3c27-40a3-bcd5-5dba2f8d5e31"
          defaultMessage="Your profile is missing a payment account. Please update your payment account to use Proof for Notaries. <paymentLink>Update payment account.</paymentLink>"
          values={{
            paymentLink: (text) => (
              <a
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  handleClick(ORG_BILLING_SETTINGS_PATH);
                }}
              >
                {text}
              </a>
            ),
          }}
        />
      ),
    };
    // Prevents the incompleteProfile banner from showing when notary is waiting on review since
    // notary has done everything they can at this point and are waiting on notary ops.
  } else if (incompleteProfile && complianceStatus !== NotaryComplianceStatuses.NEEDS_REVIEW) {
    alert = {
      icon: "doc-warning",
      kind: "danger",
      message: (
        <FormattedMessage
          id="73c30cca-ee61-4482-a935-d26a743eca07"
          defaultMessage="Your account has one or more issues that need to be resolved before you can access Proof for Notaries. <profileLink>Update profile.</profileLink>"
          values={{
            profileLink: (text) => (
              <a
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  handleClick("personal-info");
                }}
              >
                {text}
              </a>
            ),
          }}
        />
      ),
    };
  } else if (odnWithMissingNNA) {
    alert = {
      message: (
        <FormattedMessage
          id="895dddd5-a181-4d3d-8b87-a65f0b6e3f94"
          defaultMessage="Your profile is missing an NNA ID. Please add your NNA ID and ensure your NNA background check and NSA training exam expiration date are both up to date to access required trainings for the on-demand queues."
        />
      ),
      icon: "warning",
      kind: "warning",
    };
  } else if (odnWaiting) {
    alert = {
      icon: "warning",
      kind: "warning",
      message: (
        <FormattedMessage
          id="56224e16-a81e-479d-997a-4c3e8ddc9750"
          defaultMessage="Once you have completed on-demand queue training, our team will review your profile and contact you.{isNst, select, true{ You are still able to create your own transactions and meet with those signers in the meantime.} other{}}"
          values={{
            isNst: capacities.some(
              (cap) =>
                cap.type === NotaryCapacityType.BRING_YOUR_OWN_TRANSACTIONS &&
                cap.status === NotaryActivationStatus.ACCEPTED,
            ),
          }}
        />
      ),
    };
  } else if (ihnDisabled) {
    alert = {
      icon: "warning",
      kind: "warning",
      message: (
        <FormattedMessage
          id="fa805760-92a4-46f9-b4cc-ece87c7ad7cd"
          defaultMessage="Your organization has disabled your ability to take calls. Please contact your organization’s admin(s)."
        />
      ),
    };
  } else if (notaryRequiresReapproval) {
    alert = {
      icon: "warning",
      kind: "warning",
      message: (
        <FormattedMessage
          id="fa805760-92a4-46f9-b4cc-ece87c7ad7cd"
          defaultMessage="Your profile must be approved. Our team will review your information and contact you within 1-2 business days."
        />
      ),
    };
  }

  if (!alert) {
    return null;
  }
  const { icon, kind, message } = alert;
  return (
    <AlertMessage
      automationId="notary-profile-incomplete-alert-message"
      banner
      centerText
      customIcon={icon}
      kind={kind}
    >
      {message}
    </AlertMessage>
  );
}

export default memo(ProofFrameNotaryProfileBanner);
