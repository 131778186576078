import { MeetingRequestTypeEnum } from "graphql_globals";

const ON_DEMAND_KEY = "notarize-last-ondemand-meeting-id";
export const ON_DEMAND_QUEUE_PATH = "/on-demand-queue";

type ServicingUrlOptions = {
  meetingId: string;
  requestType: MeetingRequestTypeEnum;
};

/** Use this function to persist the latest meeting id picked up from on demand queue. */
export function writeMeetingIdCameFromOnDemand(meetingId: string) {
  window.sessionStorage.setItem(ON_DEMAND_KEY, meetingId);
}

/**
 * This is the counterpart to `writeMeetingIdCameFromOnDemand` to check if meeting was entered
 * from on demand queue.
 */
export function checkMeetingIdCameFromOnDemand(meetingId: string): boolean {
  return window.sessionStorage.getItem(ON_DEMAND_KEY) === meetingId;
}

/** Returns the URL an agent (notary, TR, verify) should navigate to on call pickup. */
export function getServicingAgentMeetingUrl(options: ServicingUrlOptions): string {
  const { meetingId, requestType } = options;
  switch (requestType) {
    case MeetingRequestTypeEnum.IDV:
      return `/referee-meeting/${meetingId}`;
    case MeetingRequestTypeEnum.VERIFY:
      return `/verify-meeting/${meetingId}`;
    case MeetingRequestTypeEnum.WITNESS:
      return `/remote-witness-meeting/${meetingId}`;
    case MeetingRequestTypeEnum.DEFAULT:
      return `/notary-meeting/${meetingId}`;
  }
}
