import { Outlet } from "react-router-dom";

import { SettingsPageWrapper } from "common/settingsv2/common";

import { SettingsSidebarBrandSettingsLink } from "./settings";

export const BRAND_SETTINGS_ROOT_PREFIX = "brand";

export function SettingsSidebarBrandCustomizationLink() {
  return <SettingsSidebarBrandSettingsLink />;
}

export function BrandSettings() {
  return (
    <SettingsPageWrapper>
      <Outlet />
    </SettingsPageWrapper>
  );
}
