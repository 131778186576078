import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { LogoNav } from "common/app_frame/logo_nav";
import { Card, CardHeading, CardText } from "common/core/card";
import Button from "common/core/button";
import { DeprecatedRadioButton } from "common/form/inputs/radio";
import WorkflowModal from "common/modals/workflow_modal";
import type { CustomOrgHeadersEnum } from "graphql_globals";
import { useMutation } from "util/graphql";
import { b } from "util/html";

import type { BrandSettingsCustomHeader as OrganizationBrand } from "./index.fragment.graphql";
import UpdateOrganizationBrandMutation from "./update_organization_brand.mutation.graphql";
import Styles from "./index.module.scss";

import CommonStyles from "common/settingsv2/index.module.scss";

type Props = {
  organizationBrand: OrganizationBrand;
  logoUrl: string | null;
  orgName?: string | null;
};

function Modal({
  loading,
  organizationBrand,
  logoUrl,
  orgName,
  onCancel,
  onConfirm,
}: {
  loading: boolean;
  organizationBrand: OrganizationBrand;
  logoUrl: string | null;
  orgName?: string | null;
  onCancel: () => void;
  onConfirm: (type: CustomOrgHeadersEnum) => void;
}) {
  const { header, headerOptions } = organizationBrand;

  const [radioValue, setRadioValue] = useState(header);
  const handleChange = (option: CustomOrgHeadersEnum) => {
    setRadioValue(option);
  };

  return (
    <WorkflowModal
      closeBehavior={{ tag: "with-button", onClose: onCancel }}
      title={
        <FormattedMessage
          id="481b76fa-f71d-4955-beea-4d31e1dafa79"
          defaultMessage="Choose a header style"
        />
      }
      buttons={[
        <Button
          buttonColor="action"
          variant="secondary"
          key="suppress-cancel"
          onClick={onCancel}
          disabled={loading}
        >
          <FormattedMessage id="4cad7554-814a-457f-9775-0ee6881b6121" defaultMessage="Cancel" />
        </Button>,
        <Button
          buttonColor="action"
          variant="primary"
          key="suppress-confirm"
          onClick={() => {
            onConfirm(radioValue);
          }}
          isLoading={loading}
        >
          <FormattedMessage id="98595153-5a5b-4192-b86e-5c2f5c586f05" defaultMessage="Confirm" />
        </Button>,
      ]}
      large
    >
      <FormattedMessage
        id="24537036-73c6-4fec-a9ae-f3dc569949e4"
        defaultMessage="These settings will appear on the signer login screen, signer landing pages, and transaction emails."
        tagName="span"
      />

      <div>
        {headerOptions.map((option) => (
          <div key={option} className={Styles.choiceContainer}>
            <div className={Styles.radioButton}>
              <DeprecatedRadioButton<CustomOrgHeadersEnum>
                radioValue={option}
                onChange={handleChange}
                groupValue={radioValue}
              />
            </div>

            <div className={Styles.previewContainer}>
              <div className={Styles.headerPreviewContainer}>
                <LogoNav readOnly orgName={orgName} logoUrl={logoUrl} headerStyle={option} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </WorkflowModal>
  );
}

function CustomHeader(props: Props) {
  const { logoUrl, orgName, organizationBrand } = props;
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const updateOrganizationBrand = useMutation(UpdateOrganizationBrandMutation);

  const { id: organizationBrandId, header } = organizationBrand;

  const handleClose = () => {
    setModalOpen(false);
  };
  const handleConfirm = (option: CustomOrgHeadersEnum) => {
    setLoading(true);

    updateOrganizationBrand({
      variables: {
        input: {
          organizationBrandId,
          header: option,
        },
      },
    }).finally(() => {
      setLoading(false);
      setModalOpen(false);
    });
  };

  return (
    <>
      {modalOpen && (
        <Modal
          logoUrl={logoUrl}
          orgName={orgName}
          organizationBrand={organizationBrand}
          loading={loading}
          onCancel={handleClose}
          onConfirm={handleConfirm}
        />
      )}
      <Card
        footer={
          <Button
            buttonColor="action"
            variant="secondary"
            onClick={() => {
              setModalOpen(true);
            }}
            isLoading={loading}
            disabled={!logoUrl}
            disabledHint={
              !logoUrl && (
                <FormattedMessage
                  id="e0b58b5f-94d9-452c-a78e-ed89779a20fe"
                  defaultMessage="Upload a logo to customize your header style"
                />
              )
            }
          >
            <FormattedMessage
              id="2f888385-6075-4589-b0d4-7afee07c3e9b"
              defaultMessage="Change header style"
            />
          </Button>
        }
      >
        <form name="button-color">
          <CardHeading>
            <FormattedMessage
              id="0cc6a15b-757a-42ef-b733-afdb50cad223"
              defaultMessage="Co-branded header"
            />
          </CardHeading>
          <CardText>
            <FormattedMessage
              id="b65facc2-9636-48b0-a5f4-e1103189b3f4"
              defaultMessage="The co-branded header appears on transaction emails and throughout the entire signer web experience (except during the notary meeting). Select a header style below."
            />
          </CardText>
          <div className={CommonStyles.disclaimer}>
            <FormattedMessage
              id="b9aebd94-8e85-4cba-92f2-7e85b3a9164d"
              defaultMessage="<b>Preview</b> (Note: style is not exact. This preview is intended to show basic layout only.)"
              values={{ b }}
            />
          </div>
          <div className={Styles.previewContainer}>
            <div className={Styles.headerPreviewContainer}>
              <LogoNav readOnly orgName={orgName} logoUrl={logoUrl} headerStyle={header} />
            </div>
          </div>
        </form>
      </Card>
    </>
  );
}

export default CustomHeader;
