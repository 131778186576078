import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { EasylinkTypes } from "graphql_globals";
import PopoutMenu from "common/core/popout_menu";
import { PopoutMenuMultilineItem } from "common/core/popout_menu/multiline";
import Button from "common/core/button";
import { DashboardDropdownIcon, DashboardDropdownTitle } from "common/dashboard/dropdown";
import { Badge } from "common/core/badge";
import { getCreateEasylinkUrl } from "common/dashboard/easy_link/create";
import { NEW_EASYLINK_MESSAGES } from "common/dashboard/easy_link";

import Styles from "./new_easylink_popout_menu.module.scss";

export function NewEasylinkPopoutMenu({
  availableEasylinkTypes,
}: {
  availableEasylinkTypes: EasylinkTypes[];
}) {
  const navigate = useNavigate();

  return (
    <PopoutMenu
      placement="bottomRight"
      target={
        <Button fullwidth buttonColor="action" variant="primary" automationId="new-easylink">
          <FormattedMessage
            id="4733eecb-24db-42dd-ba51-86ac683a88e3"
            defaultMessage="Create new EasyLink"
          />
          <DashboardDropdownIcon />
        </Button>
      }
    >
      {() => (
        <>
          <DashboardDropdownTitle>
            <FormattedMessage
              id="465722fe-837a-4e07-9a38-1f9c7674d2a0"
              defaultMessage="Select EasyLink type"
            />
          </DashboardDropdownTitle>
          {availableEasylinkTypes.includes(EasylinkTypes.IDENTIFY) && (
            <PopoutMenuMultilineItem
              link
              data-automation-id="new-easylink-identify"
              onClick={() => {
                navigate(getCreateEasylinkUrl(EasylinkTypes.IDENTIFY));
              }}
              primaryContent={
                <>
                  {NEW_EASYLINK_MESSAGES[EasylinkTypes.IDENTIFY].text}
                  {/* Remove in BIZ-6131 */}
                  <Badge kind="new" className={Styles.newBadge}>
                    <FormattedMessage
                      id="d1ae600a-06f9-436e-ad0d-0f7a85b63fde"
                      defaultMessage="New"
                    />
                  </Badge>
                </>
              }
              secondaryContent={NEW_EASYLINK_MESSAGES[EasylinkTypes.IDENTIFY].subText}
              iconName={NEW_EASYLINK_MESSAGES[EasylinkTypes.IDENTIFY].iconName}
              withFixedWidth
            />
          )}
          {availableEasylinkTypes.includes(EasylinkTypes.VERIFY) && (
            <PopoutMenuMultilineItem
              link
              data-automation-id="new-easylink-verify"
              onClick={() => {
                navigate(getCreateEasylinkUrl(EasylinkTypes.VERIFY));
              }}
              primaryContent={
                <>
                  {NEW_EASYLINK_MESSAGES[EasylinkTypes.VERIFY].text}
                  <Badge kind="new" className={Styles.newBadge}>
                    <FormattedMessage
                      id="d6761254-46dd-43bf-871b-0083eabc78e6"
                      defaultMessage="New"
                    />
                  </Badge>
                </>
              }
              secondaryContent={NEW_EASYLINK_MESSAGES[EasylinkTypes.VERIFY].subText}
              iconName={NEW_EASYLINK_MESSAGES[EasylinkTypes.VERIFY].iconName}
              withFixedWidth
            />
          )}
          {availableEasylinkTypes.includes(EasylinkTypes.DOCUMENTS_PROVIDED) && (
            <PopoutMenuMultilineItem
              link
              data-automation-id="new-easylink-template"
              onClick={() => {
                navigate(getCreateEasylinkUrl(EasylinkTypes.DOCUMENTS_PROVIDED));
              }}
              primaryContent={NEW_EASYLINK_MESSAGES[EasylinkTypes.DOCUMENTS_PROVIDED].text}
              secondaryContent={NEW_EASYLINK_MESSAGES[EasylinkTypes.DOCUMENTS_PROVIDED].subText}
              iconName={NEW_EASYLINK_MESSAGES[EasylinkTypes.DOCUMENTS_PROVIDED].iconName}
              withFixedWidth
            />
          )}
          {availableEasylinkTypes.includes(EasylinkTypes.SIGNER_UPLOAD) && (
            <PopoutMenuMultilineItem
              link
              data-automation-id="new-easylink-signer-upload"
              onClick={() => {
                navigate(getCreateEasylinkUrl(EasylinkTypes.SIGNER_UPLOAD));
              }}
              primaryContent={NEW_EASYLINK_MESSAGES[EasylinkTypes.SIGNER_UPLOAD].text}
              secondaryContent={NEW_EASYLINK_MESSAGES[EasylinkTypes.SIGNER_UPLOAD].subText}
              iconName={NEW_EASYLINK_MESSAGES[EasylinkTypes.SIGNER_UPLOAD].iconName}
              withFixedWidth
            />
          )}
        </>
      )}
    </PopoutMenu>
  );
}
