import type { ReactNode } from "react";
import { FormattedMessage, FormattedList } from "react-intl";
import classnames from "classnames";

import { MasterPrivacyPolicyUrl, UserTermsOfService } from "common/tos";
import { userFullName } from "util/user";
import { Payer, RequiredFeature } from "graphql_globals";
import DiscountCheck from "common/modals/discount_modal/discount_check";
import { VerticalStepIndicator } from "common/signer/common";
import { Row, Container, useMobileScreenClass } from "common/core/responsive";
import Link from "common/core/link";
import Button from "common/core/button";
import { Heading, Paragraph } from "common/core/typography";
import { ALLOWED_PRIMARY_IDS_URL, PS1583_ALLOWED_IDS_URL } from "constants/support";
import { DOC_CATEGORIES } from "constants/document";
import Icon from "common/core/icon";
import { useViewer } from "util/viewer_wrapper";
import { useMutation } from "util/graphql";
import SignTosMutation from "common/modals/terms_of_service/sign_tos_mutation.graphql";
import AlertMessage from "common/core/alert_message";
import { ProofFooter } from "common/proof_footer";

import LandingContacts from "./contacts";
import LandingSupportInfo from "./support";
import LandingCustomBlock from "./custom_block";
import Styles from "./common.module.scss";
import type {
  SignerLanding_viewer as Viewer,
  SignerLanding_documentBundle_DocumentBundle as DocumentBundle,
} from "../index.query.graphql";
import {
  LandingColumn,
  useHandleReadyToSign,
  WelcomeText,
  LandingWrapper,
  NoMeetingWarning,
} from "./common";

type Props = {
  documentBundle: DocumentBundle;
  viewer: Viewer;
  readOnly?: boolean;
  isEsign: boolean;
  handleBlockedAction: (action: () => void) => void;
};

type InnerProps = Props & {
  renderExpiredOrInactiveContent: () => ReactNode;
};

/**
 * @description Fallback component for non-RON transactions.
 */

const BitLandingContentV2 = function BitLandingContentV2({
  documentBundle,
  viewer,
  readOnly,
  isEsign,
  renderExpiredOrInactiveContent,
  handleBlockedAction,
}: InnerProps) {
  const {
    organizationTransaction,
    documents: { totalCount, edges },
    availableForSigning,
    requiredFeatures,
    participants,
  } = documentBundle;
  const isMobile = useMobileScreenClass();
  const { requiresNsaMeeting, payer, publicOrganization } = organizationTransaction;
  const { organizationBrand } = publicOrganization;
  const brandName = organizationBrand.name;
  const { refetch: refetchViewer } = useViewer();
  const signTermsOfService = useMutation(SignTosMutation);
  const skipDocPreview = !availableForSigning;
  const isPS1583 = edges.some(
    (edge) =>
      edge.node.classification?.category?.toUpperCase() === DOC_CATEGORIES.PS1583 ||
      edge.node.classification?.category?.toUpperCase() === DOC_CATEGORIES.PS1583_ATTESTATION,
  );
  const isProofing = edges.some((edge) => edge.node.proofingRequired);
  const expiredOrInactive = renderExpiredOrInactiveContent();

  const hasSigningOrder = requiredFeatures?.includes(RequiredFeature.SIGNING_ORDER);
  const currentParticipant = participants?.find((p) => p?.userId === viewer.user?.id);
  const nextInOrder = participants?.filter((p) => p?.canStartSigning);
  const participantNames = nextInOrder?.map((p) => userFullName(p));

  const buttonCTA = () => {
    if (skipDocPreview || isEsign) {
      return (
        <FormattedMessage
          id="054f830e-dc9c-45e3-8316-7cd008f635a6"
          defaultMessage="View documents"
        />
      );
    }
    return (
      <FormattedMessage
        id="6d2e94e8-f5f2-4346-b53d-5a378df8d5bc"
        defaultMessage="Review and sign"
      />
    );
  };

  const NotarizationSteps = () => (
    <VerticalStepIndicator
      listItems={[
        {
          item: (
            <FormattedMessage
              id="185fef21-ac90-4296-a974-7d9214f36779"
              defaultMessage="Review documents"
            />
          ),
          description: (
            <FormattedMessage
              id="d1ba78e4-46ff-4172-b38b-337f76cf89bc"
              defaultMessage="You have {totalCount, plural, one {one document} other {documents}} to review."
              values={{ totalCount }}
            />
          ),
        },
        {
          item: (
            <FormattedMessage
              id="3a887d20-a932-4ffb-8080-d027c341c051"
              defaultMessage="Verify your identity"
            />
          ),
          description: (
            <FormattedMessage
              id="fd2a2e03-f9a8-4c7d-919c-1eed26cc9bbf"
              defaultMessage="Answer a few identity questions and take photos of your ID."
            />
          ),
        },
        {
          item: (
            <FormattedMessage
              id="43fe33fa-2b2f-4954-887e-5637eecc7bb6"
              defaultMessage="Meet with a notary over video call"
            />
          ),
          description: (
            <FormattedMessage
              id="9a52f19f-12b6-46c5-bcc3-67b5853d8ccf"
              defaultMessage="We'll connect you with a certified notary over a secure video call."
            />
          ),
        },
      ]}
    />
  );

  const documentMessage = (
    <AlertMessage kind="info" className={Styles.documentMessageText}>
      <Paragraph size={isMobile ? "defaultSize" : "large"}>
        {hasSigningOrder ? (
          !currentParticipant?.canStartSigning ? (
            <FormattedMessage
              id="090d83d2-adc8-4074-941c-57a0dddb5357"
              defaultMessage="It's {formattedParticipantNames}'s turn to sign. You will receive an email when it's your turn."
              values={{
                formattedParticipantNames: participantNames?.length ? (
                  <FormattedList type="conjunction" value={participantNames} />
                ) : (
                  <FormattedMessage
                    id="65d93c86-d981-493c-8e84-5ecc2af02db5"
                    defaultMessage="another signer"
                  />
                ),
              }}
            />
          ) : (
            <FormattedMessage
              id="e59c5987-d919-4658-8084-1cc5be53ff91"
              defaultMessage="Someone else is currently signing."
            />
          )
        ) : (
          <FormattedMessage
            id="87942a68-489f-40b8-a516-e52ff2bb7c59"
            defaultMessage="Someone else is currently signing. We’ll send an email shortly when we’re ready for you to sign. In the meantime, you can view your documents."
          />
        )}
      </Paragraph>
    </AlertMessage>
  );

  const getStarted = useHandleReadyToSign(documentBundle, viewer, readOnly);
  const signTosAndGetStarted = async () => {
    if (viewer.user?.applicableTou.every((tou) => !tou.acceptedAt)) {
      await signTermsOfService({
        variables: { input: { userId: viewer.user.id } },
      });
      await refetchViewer();
    }
    getStarted();
  };
  const renderBody = () => {
    if (expiredOrInactive) {
      return expiredOrInactive;
    }

    return (
      <>
        <LandingColumn xs={12} lg={6}>
          <div>
            <WelcomeText />
            <Heading level="h2" textStyle="headingFour" className={Styles.subheader}>
              <FormattedMessage
                id="99490a42-a621-46f8-907f-a67f93f84440"
                defaultMessage="{brandName} has sent you {totalCount, plural, one {a document} other {documents}} to {cta, select, esign {review and sign} notarize {notarize online} other {sign online}}"
                values={{
                  brandName,
                  totalCount,
                  cta: isEsign ? "esign" : "other",
                }}
              />
            </Heading>
            <Paragraph size="large" className={Styles.subheaderInfo}>
              <FormattedMessage
                id="6042abe6-1616-48c7-b773-ac96f451a666"
                defaultMessage="This will take about 15 minutes to complete. Notaries are online 24x7."
              />
            </Paragraph>
            <div className={Styles.features}>
              <Heading level="h3" textStyle="headingSix">
                <FormattedMessage
                  id="873f808c-b8fd-472d-a83d-378fb1e96ff7"
                  defaultMessage="Here's what you'll need:"
                />
              </Heading>
              <ul>
                <li className={Styles.feature}>
                  <Icon name="tick" className={Styles.featureIcon} />
                  <Paragraph size="large">
                    <FormattedMessage
                      id="d02672b1-3273-4f38-95e5-32558088b1b7"
                      defaultMessage="A valid photo ID <link>See accepted IDs</link>"
                      values={{
                        link: (msg: ReactNode[]) => (
                          <Link
                            href={
                              isPS1583 || isProofing
                                ? PS1583_ALLOWED_IDS_URL
                                : ALLOWED_PRIMARY_IDS_URL
                            }
                          >
                            {msg}
                          </Link>
                        ),
                      }}
                    />
                  </Paragraph>
                </li>
                <li className={Styles.feature}>
                  <Icon name="tick" className={Styles.featureIcon} />
                  <Paragraph size="large">
                    <FormattedMessage
                      id="14576a50-f72e-4094-b110-fc848fd52caa"
                      defaultMessage="A smartphone, tablet, or computer with audio and video"
                    />
                  </Paragraph>
                </li>
                {documentBundle.kbaRequired && (
                  <li className={Styles.feature}>
                    <Icon name="tick" className={Styles.featureIcon} />
                    <Paragraph size="large">
                      <FormattedMessage
                        id="9489871d-5e8c-4fd2-b433-a36a99a8b8a7"
                        defaultMessage="The last four digits of your SSN"
                      />
                    </Paragraph>
                  </li>
                )}
                {payer === Payer.CUSTOMER && (
                  <li className={Styles.feature}>
                    <Icon name="tick" className={Styles.featureIcon} />
                    <Paragraph size="large">
                      <FormattedMessage
                        id="6730a567-bfb0-4dab-b678-7a4ac099c040"
                        defaultMessage="A credit card for the notarization fee"
                      />
                    </Paragraph>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </LandingColumn>
        <LandingColumn xs={12} lg={6}>
          <div className={Styles.documentCanvas}>
            <div className={classnames(Styles.documentContainerNoMargin, Styles.documentContainer)}>
              <NotarizationSteps />

              {!isMobile && (
                <div className={Styles.documentMessage}>
                  {skipDocPreview && documentMessage}
                  <Button
                    className={Styles.documentMessageButton}
                    onClick={() => handleBlockedAction(signTosAndGetStarted)}
                    automationId="get-started-button"
                    buttonColor="action"
                    buttonSize="large"
                    variant="primary"
                  >
                    {buttonCTA()}
                  </Button>
                  {!requiresNsaMeeting && <NoMeetingWarning totalCount={totalCount} />}
                </div>
              )}
            </div>

            <div className={classnames(Styles.terms, Styles.termsEsign)}>
              <Paragraph size="small" textColor="subtle" textAlign="center">
                <FormattedMessage
                  id="c3965ba8-0997-4a3c-92c4-cfaee4a0801a"
                  defaultMessage='By clicking "{ctaText}", or signing up with your Google account, you are agreeing to the {termsOfUse}. For information on our privacy and data use practices please see our {privacyPolicy}.'
                  values={{
                    termsOfUse: <UserTermsOfService underlined />,
                    privacyPolicy: <MasterPrivacyPolicyUrl underlined />,
                    ctaText: buttonCTA(),
                  }}
                />
              </Paragraph>
            </div>
          </div>
        </LandingColumn>
      </>
    );
  };

  return (
    <div className={Styles.wrapper}>
      <DiscountCheck />
      <div className={Styles.containerWrapper}>
        <Container className={Styles.container}>
          <Row>{renderBody()}</Row>
        </Container>
        <LandingCustomBlock organizationBrand={organizationBrand} />
        <Container className={Styles.container}>
          <LandingContacts contacts={organizationTransaction.contacts} />
          <LandingSupportInfo />
          <ProofFooter topMargin />
        </Container>
      </div>

      {isMobile && (
        <div className={Styles.mobileFooter}>
          {skipDocPreview && documentMessage}
          <Button
            className={Styles.documentMessageButton}
            onClick={() => handleBlockedAction(signTosAndGetStarted)}
            automationId="get-started-button"
            buttonColor="action"
            variant="primary"
          >
            {buttonCTA()}
          </Button>
          {!requiresNsaMeeting && <NoMeetingWarning totalCount={totalCount} />}
        </div>
      )}
    </div>
  );
};

function BitLandingContentV2Wrapper(props: Props) {
  return (
    <LandingWrapper automationPrefix="bit-landing" {...props}>
      {(renderExpiredOrInactiveContent) => (
        <BitLandingContentV2
          renderExpiredOrInactiveContent={renderExpiredOrInactiveContent}
          {...props}
        />
      )}
    </LandingWrapper>
  );
}

export { BitLandingContentV2Wrapper as BitLandingV2 };
